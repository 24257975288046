import Check from "../../../images/about/check.png"
function Amenities(){
	return(
		<section className="pt-[80px] lg:pt-[100px]">
			<div className="container">
				<div className="grid grid-cols-12 gap-x-[30px] xl:gap-x-[55px] mb-[-30px]">
					<div className="col-span-12 lg:col-span-5 mb-[30px]">
						<h4 className="font-lora text-primary text-[24px] leading-[1.277] sm:text-[36px] capitalize mb-[40px] font-medium">
							Property Amenities
							<span className="text-secondary">.</span>
						</h4>
						<p>Huge number of propreties availabe here for buy, sell and Rent. Also you find here co-living property lots opportunity you have to choose here and enjoy huge discount.</p>
						<div className="flex flex-wrap items-center justify-between mt-[45px]">
							<ul>
								<li className="flex flex-wrap mb-[40px]">
									<img className="self-start mt-[8px] mr-[15px]" src={Check} loading="lazy" alt="icon" width="20" height="20" />
									<span className="flex-1 max-w-[315px]">Properties are most budget friendly so you have opportunity to find the best one</span>
								</li>
								<li className="flex flex-wrap mb-[40px]">
									<img className="self-start mt-[8px] mr-[15px]" src={Check} loading="lazy" alt="icon" width="20" height="20" />
									<span className="flex-1 max-w-[315px]">Properties are most budget friendly so you have opportunity to find the best one</span>
								</li>
								<li className="flex flex-wrap mb-[40px]">
									<img className="self-start mt-[8px] mr-[15px]" src={Check} loading="lazy" alt="icon" width="20" height="20" />
									<span className="flex-1 max-w-[315px]">Properties are most budget friendly so you have opportunity to find the best one</span>
								</li>
								<li className="flex flex-wrap mb-[40px]">
									<img className="self-start mt-[8px] mr-[15px]" src={Check} loading="lazy" alt="icon" width="20" height="20" />
									<span className="flex-1 max-w-[315px]">Properties are most budget friendly so you have opportunity to find the best one</span>
								</li>
							</ul>
						</div>
					</div>

					<div className="col-span-12 lg:col-span-7 mb-[30px]">
						<h4 className="font-lora text-primary text-[24px] leading-[1.277] sm:text-[28px] capitalize mb-[10px] font-medium">Send Message to Fleming de Silva</h4>
						<p className="mb-[45px] max-w-[460px]">Huge number of propreties availabe here for buy, sell and Rent. Also you find here co-living property, lots opportunity</p>
						<form action="#" className="grid grid-cols-12 gap-x-[20px] gap-y-[30px]">
							<div className="col-span-12 md:col-span-6">
								<input className="font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-[#1B2D40] border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)]" type="text" placeholder="First Name" />
							</div>

							<div className="col-span-12 md:col-span-6">
								<input className="font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-[#1B2D40] border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)]" type="text" placeholder="Last Name" />
							</div>

							<div className="col-span-12 md:col-span-6">
								<input className="font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-[#1B2D40] border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)]" type="text" placeholder="Phone number" />
							</div>

							<div className="col-span-12 md:col-span-6">
								<input className="font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-[#1B2D40] border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)]" type="email" placeholder="Email Address" />
							</div>

							<div className="col-span-12">
								<textarea className="h-[196px] font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-[#1B2D40] border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] resize-none" name="textarea" id="textarea" cols="30" rows="10" placeholder="Message"></textarea>
							</div>

							<div className="col-span-12">
								<button type="submit" className="before:rounded-md before:block before:absolute before:left-auto before:right-0 before:inset-y-0 before:-z-[1] before:bg-secondary before:w-0 hover:before:w-full hover:before:left-0 hover:before:right-auto before:transition-all leading-none px-[30px] py-[15px] capitalize font-medium text-white block text-[14px] xl:text-[16px] relative after:block after:absolute after:inset-0 after:-z-[2] after:bg-primary after:rounded-md after:transition-all">Contact us</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>

	)
}

export default Amenities