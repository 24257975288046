import React from "react";

import HeaderTop from '../HeaderTop'
import BreadCrumb from '../BreadCrumb'
import PopularProperties from './PopularProperties'
import Guide from './Guide'
import YouShouldDo from "./YouShouldDo"
import VideoSection from '../VideoSection'
import NewsLetter from '../NewsLetter'

import BreadCrumbs from '../data/breadcrumbs.json'
function Buyer(){
	const BreadCrumbData = BreadCrumbs.buyer
	return(
        <>
		    <HeaderTop />
		    <BreadCrumb {...BreadCrumbData}/>
			<PopularProperties />
			<Guide />
			<YouShouldDo />
			<VideoSection />
			<NewsLetter />
		</>
    )
}

export default Buyer;