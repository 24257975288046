import {React, useState} from "react";

import PropertiesCard from "../PropertyCard";
import TopFilter from "./TopFilter";

import Property from '../data/properties.json'
function PopularProperties(){
	const property = Property


	return(
		<section className="popular-properties py-[80px]  ">
            <div className="container">
                <div className="grid grid-cols-12 gap-4 relative">
                    <TopFilter />
                </div>
                <div className="grid grid-cols-12 items-center mb-4">
                    <div className="col-span-12 lg:col-span-4">
                        
                    </div>
                    <div className="col-span-12 lg:col-span-8 ">
                        <div className="grid grid-cols-12 ">
                            <div className="col-span-8 items-start">
                                <ul class="pagination flex flex-wrap items-center ">
                                    <li class="mx-2">
                                        <a class="flex flex-wrap items-center justify-center  w-[26px] h-[26px] bg-primary rounded-full text-orange leading-none transition-all hover:bg-secondary text-white text-[12px]" href="#">
                                            <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(.clip0_876_580)">
                                                    <path d="M5.8853 10.0592C5.7326 10.212 5.48474 10.212 5.33204 10.0592L0.636322 5.36134C0.48362 5.20856 0.48362 4.96059 0.636322 4.80782L5.33204 0.109909C5.48749 -0.0403413 5.73535 -0.0359829 5.8853 0.119544C6.03181 0.271171 6.03181 0.511801 5.8853 0.663428L1.46633 5.08446L5.8853 9.50573C6.03823 9.65873 6.03823 9.90648 5.8853 10.0592Z" fill="white" />
                                                </g>
                                                <defs>
                                                    <clipPath class="clip0_876_580">
                                                        <rect width="5.47826" height="10.1739" fill="white" transform="matrix(-1 0 0 1 6 0)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </a>
                                    </li>

                                    <li class="mx-2">
                                        <a class="flex flex-wrap items-center justify-center  w-[26px] h-[26px] leading-none hover:text-secondary" href="#">1</a>
                                    </li>

                                    <li class="mx-2">
                                        <a class="flex flex-wrap items-center justify-center  w-[26px] h-[26px] leading-none hover:text-secondary" href="#">2</a>
                                    </li>

                                    <li class="mx-2">
                                        <a class="flex flex-wrap items-center justify-center  w-[26px] h-[26px] leading-none hover:text-secondary" href="#">3</a>
                                    </li>

                                    <li class="mx-2">
                                        <a class="flex flex-wrap items-center justify-center  w-[26px] h-[26px] leading-none hover:text-secondary" href="#">4</a>
                                    </li>

                                    <li class="mx-2">
                                        <span>---</span>
                                    </li>

                                    <li class="mx-2">
                                        <a class="flex flex-wrap items-center justify-center  w-[26px] h-[26px] leading-none hover:text-secondary" href="#">25</a>
                                    </li>

                                    <li class="mx-2">
                                        <a class="flex flex-wrap items-center justify-center  w-[26px] h-[26px] bg-primary rounded-full text-orange leading-none transition-all hover:bg-secondary text-white text-[12px]" href="#">
                                            <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(.clip0_876_576)">
                                                    <path d="M0.114699 10.0592C0.267401 10.212 0.515257 10.212 0.667959 10.0592L5.36368 5.36134C5.51638 5.20856 5.51638 4.96059 5.36368 4.80782L0.667959 0.109909C0.512505 -0.0403413 0.26465 -0.0359829 0.114699 0.119544C-0.031813 0.271171 -0.031813 0.511801 0.114699 0.663428L4.53367 5.08446L0.114699 9.50573C-0.038233 9.65873 -0.038233 9.90648 0.114699 10.0592Z" fill="white" />
                                                </g>
                                                <defs>
                                                    <clipPath class="clip0_876_576">
                                                        <rect width="5.47826" height="10.1739" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-span-4 text-right">
                                <span className="text-primary">Sort by:</span>
                                <select name="select" id="select" className="bg-white text-[#9C9C9C] text[14px] capitalize cursor-pointer nice-select sorting-select">
                                    <option defaultValue="0">Default Order</option>
                                    <option value="1">A to Z</option>
                                    <option value="2">Z to A</option>
                                    <option value="3">All</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                    
                <div className="grid grid-cols-12 gap-4 " >
                    <div className="col-span-4 h-dvh">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d29262.852409004598!2d87.30059541055907!3d23.537661297200696!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f76f7a2fd53fb7%3A0xc68810d23457580e!2sStarpact%20Global%20Services!5e0!3m2!1sen!2sin!4v1719224278657!5m2!1sen!2sin" class="mb-8 w-full h-full rounded shadow-sm border border-secondary" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    
                    <div className="col-span-8 mb-[30px] h-dvh overflow-auto">
                        <div id="grid" className="grid ">
                            <div className="col-span-12">
                                <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-[30px]">
									{property.properties.map((item, index) => (
										<PropertiesCard key={index} data={item} />
									))}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
	)
}

export default PopularProperties;