import React from "react";


import HeroSection from './HeroSection';
import AdvancedSearchTab from './AdvancedSearchTab';
import ExploreStates from './ExploreStates';
import AboutUS from './AboutUs';
import FeaturedProperties from './FeaturedProperties';
import WhatWeDo from './WhatWeDo';
import BrandSection from './BrandSection';
import VideoSection from '../VideoSection';
import NewsLetter from '../NewsLetter';


function Home(){
	return(
        <>
            <HeroSection />
            <AdvancedSearchTab />
            <ExploreStates />
            <AboutUS />
            <FeaturedProperties />
            <WhatWeDo />
            <BrandSection />
            <VideoSection />
            <NewsLetter />
        </>
    )
}

export default Home;