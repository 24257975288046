import BreadCrumb from "../../BreadCrumb"
import HeaderTop from "../../HeaderTop"

import BreadCrumbs from "../../data/breadcrumbs.json"
import AgentDetails from "./AgentDetails"
import Amenities from "./Amenities"
import WorkContact from "./WorkContact"
import NewsLetter from "../../NewsLetter"
import Reviews from "./Reviews"
function Agent(){
	const BreadCrumbData = BreadCrumbs.agent
	return(
		<>
			<HeaderTop />
			<BreadCrumb {...BreadCrumbData}/>
			<AgentDetails />
			<Amenities />
			<WorkContact />
			<Reviews />
			<NewsLetter />
		</>
	)
}

export default Agent