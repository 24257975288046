function FindAgent(){
	return(
		<div className="container">
			<div className="col-span-12 top-filter mt-12">
				<div className="col-span-12 my-5">
					<h4 className="text-base font-semibold">Real estate agents in Chicago, IL</h4>
				</div>
				<form action="" className="relative grid grid-cols-12 gap-x-4">
					<div className="relative mb-[25px] bg-white col-span-12 md:col-span-6 lg:col-span-3 ">
						<input className="font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white" type="text" placeholder="City, Neighbourhood, or Zip Code" />
						<svg className="absolute top-1/2 -translate-y-1/2 z-[1] left-[20px] pointer-events-none" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M5.39648 6.41666H8.60482" stroke="#016450" strokeWidth="1.5" strokeLinecap="round" />
							<path d="M7 8.02083V4.8125" stroke="#016450" strokeWidth="1.5" strokeLinecap="round" />
							<path d="M2.11231 4.9525C3.26148 -0.0991679 10.7456 -0.0933345 11.889 4.95833C12.5598 7.92167 10.7165 10.43 9.10064 11.9817C7.92814 13.1133 6.07314 13.1133 4.89481 11.9817C3.28481 10.43 1.44148 7.91583 2.11231 4.9525Z" stroke="#0B2C3D" strokeWidth="1.5" />
						</svg>
					</div>
					<div className="relative mb-[25px] bg-white col-span-12 md:col-span-6 lg:col-span-3 ">
						<input className="font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white" type="text" placeholder="Agent Name" />
						<svg className="absolute top-1/2 -translate-y-1/2 z-[1] left-[20px] pointer-events-none" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clipPath="url(#clip0_928_754)">
								<path d="M4.64311 0H0V4.64311H4.64311V0ZM3.71437 3.71437H0.928741V0.928741H3.71437V3.71437Z" fill="#0B2C3D" />
								<path d="M8.35742 0V4.64311H13.0005V0H8.35742ZM12.0718 3.71437H9.28616V0.928741H12.0718V3.71437Z" fill="#0B2C3D" />
								<path d="M0 13H4.64311V8.35689H0V13ZM0.928741 9.28563H3.71437V12.0713H0.928741V9.28563Z" fill="#0B2C3D" />
								<path d="M8.35742 13H13.0005V8.35689H8.35742V13ZM9.28616 9.28563H12.0718V12.0713H9.28616V9.28563Z" fill="#0B2C3D" />
								<path d="M6.96437 0H6.03563V6.03563H0V6.96437H6.03563V13H6.96437V6.96437H13V6.03563H6.96437V0Z" fill="#0B2C3D" />
							</g>
							<defs>
								<clipPath id="clip0_928_754">
									<rect width="13" height="13" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</div>
					<div className="relative mb-[25px] bg-white col-span-6 md:col-span-6 lg:col-span-2">
						<svg className="absolute top-1/2 -translate-y-1/2 z-[1] left-[20px] pointer-events-none" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clipPath="url(#clip0_928_754)">
								<path d="M4.64311 0H0V4.64311H4.64311V0ZM3.71437 3.71437H0.928741V0.928741H3.71437V3.71437Z" fill="#0B2C3D" />
								<path d="M8.35742 0V4.64311H13.0005V0H8.35742ZM12.0718 3.71437H9.28616V0.928741H12.0718V3.71437Z" fill="#0B2C3D" />
								<path d="M0 13H4.64311V8.35689H0V13ZM0.928741 9.28563H3.71437V12.0713H0.928741V9.28563Z" fill="#0B2C3D" />
								<path d="M8.35742 13H13.0005V8.35689H8.35742V13ZM9.28616 9.28563H12.0718V12.0713H9.28616V9.28563Z" fill="#0B2C3D" />
								<path d="M6.96437 0H6.03563V6.03563H0V6.96437H6.03563V13H6.96437V6.96437H13V6.03563H6.96437V0Z" fill="#0B2C3D" />
							</g>
							<defs>
								<clipPath id="clip0_928_754">
									<rect width="13" height="13" fill="white" />
								</clipPath>
							</defs>
						</svg>
						
						<select className="nice-select font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white appearance-none cursor-pointer">
							<option value="0">Speciality</option>
							<option value="5" >Any</option>
							<option value="1">Buyer Agent</option>
							<option value="2">Seller Agent</option>
							<option value="3">Listing Agent</option>
							<option value="4">Relocation</option>
							<option value="5">Foreclosure</option>
							<option value="6">Short Sale</option>
							<option value="7">Consulting</option>
						</select>
					</div>
					<div className="relative mb-[25px] bg-white col-span-6 md:col-span-6 lg:col-span-2">
						<svg className="absolute top-1/2 -translate-y-1/2 z-[1] left-[20px] pointer-events-none" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clipPath="url(#clip0_928_754)">
								<path d="M4.64311 0H0V4.64311H4.64311V0ZM3.71437 3.71437H0.928741V0.928741H3.71437V3.71437Z" fill="#0B2C3D" />
								<path d="M8.35742 0V4.64311H13.0005V0H8.35742ZM12.0718 3.71437H9.28616V0.928741H12.0718V3.71437Z" fill="#0B2C3D" />
								<path d="M0 13H4.64311V8.35689H0V13ZM0.928741 9.28563H3.71437V12.0713H0.928741V9.28563Z" fill="#0B2C3D" />
								<path d="M8.35742 13H13.0005V8.35689H8.35742V13ZM9.28616 9.28563H12.0718V12.0713H9.28616V9.28563Z" fill="#0B2C3D" />
								<path d="M6.96437 0H6.03563V6.03563H0V6.96437H6.03563V13H6.96437V6.96437H13V6.03563H6.96437V0Z" fill="#0B2C3D" />
							</g>
							<defs>
								<clipPath id="clip0_928_754">
									<rect width="13" height="13" fill="white" />
								</clipPath>
							</defs>
						</svg>
						
						<select className="nice-select font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white appearance-none cursor-pointer">
							<option value="0">Language</option>
							<option value="1" >English</option>
							<option value="2">Hindi</option>
							<option value="3">Bengali</option>
							<option value="4">Farsi</option>
							<option value="5">French</option>
						</select>
					</div>
					
					
					<div className="relative mb-[25px] bg-white col-span-6 md:col-span-6 lg:col-span-2 ">
						<button type="" className="w-full inline-block z-[1] before:rounded-md before:block before:absolute before:left-auto before:right-0 before:inset-y-0 before:z-[-1] before:bg-secondary before:w-0 hover:before:w-full hover:before:left-0 hover:before:right-auto before:transition-all leading-none px-[30px] py-[15px] capitalize font-medium text-white text-[14px] xl:text-[16px] relative after:block after:absolute after:inset-0 after:z-[-2] after:bg-primary after:rounded-md after:transition-all">Find Agent</button>
					</div>
					
				</form>
			</div>
		</div>
	)
}

export default FindAgent;