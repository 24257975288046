import React from "react";

import HeaderTop from "../HeaderTop";
import BreadCrumb from '../BreadCrumb';
import NewsLetter from "../NewsLetter";
import LoginForm from "./LoginForm";
import BreadCrumbs from "../data/breadcrumbs.json"

function Login(){
	const BreadCrumbData = BreadCrumbs.login
	return(
		<>	<HeaderTop />
			<BreadCrumb {...BreadCrumbData}/>
			<LoginForm />
			<NewsLetter />
		</>

	)
}

export default Login;