import React from "react"
import About1 from '../../images/about/about1.png'
import Dollar from '../../images/icon/doller.png'
import Location from "../../images/icon/location.png"
import Trusted from "../../images/icon/trusted.png"

function AboutUs(){
	return(
	<section className="about-section lg:pt-[55px]">
		<div className="container">
			<div className="grid grid-cols-12 gap-6 items-center">
				<div className="col-span-12 lg:col-span-6">
					<span className="text-secondary text-tiny inline-block mb-2">About The Company</span>
					<h2 className="font-lora text-black text-[24px] sm:text-[30px] leading-[1.277] xl:text-xl capitalize mb-5 lg:mb-16 font-medium max-w-[500px]">
						Modern, Creative
					<br />
					<span className="sp-brand">& Luxury Homes</span>
					<span className="sp-brand">.</span>
					</h2>
					<div className="scene" data-relative-input="true">
						<img src={About1} data-depth="0.1"  className="" loading="lazy" width="729" height="663" alt="about Image" />
					</div>
				</div>
				<div className="col-span-12 lg:col-span-6 lg:pl-[70px]">
					<p className="max-w-[448px] ">
						Founded in 2021, Oren Access revolutionizes real
						estate by creating a networking platform for agents, 
						buyers, sellers, and industry professionals, 
						empowering agents and dismantling traditional MLS monopolies.
					</p>

					<div className="-mb-10 mt-12 xl:mt-[70px] 2xl:mt-[100px]">
						<div className="flex flex-wrap mb-5 lg:mb-10">
							<img src={Dollar} className="self-start mr-5" loading="lazy" width="50" height="50" alt="about Image" />
							<div className="flex-1">
								<h3 className="font-lora sp-brand text-[22px] xl:text-lg capitalize mb-2">Connecting the Real Estate Community</h3>
								<p className="max-w-[448px]">
									Oren Access simplifies networking, enabling agents and sellers to market 
									properties effectively while helping buyers connect with experienced 
									professionals like bankers, lenders, and attorneys for seamless purchases.
								</p>
							</div>

						</div>
						<div className="flex flex-wrap mb-5 lg:mb-10">
							<img src={Location} className="self-start mr-5" loading="lazy" width="50" height="50" alt="about Image" />
							<div className="flex-1">
								<h3 className="font-lora sp-brand text-[22px] xl:text-lg capitalize mb-2">Expanding Market Reach</h3>
								<p className="max-w-[448px]">
									We market your profession and property extensively online, ensuring maximum visibility. 
									We also encourage commission splits to attract more buyers to our platform.
								</p>
							</div>

						</div>
						<div className="flex flex-wrap mb-5 lg:mb-10">
							<img src={Trusted} className="self-start mr-5" loading="lazy" width="50" height="50" alt="about Image" />
							<div className="flex-1">
								<h3 className="font-lora sp-brand text-[22px] xl:text-lg capitalize mb-2">Nationwide Collaboration</h3>
								<p className="max-w-[448px]">
									Our platform allows agents to list properties across states, making it easier for sellers to find agents and for buyers to discover homes nationwide.
									With Oren Access, achieving your real estate goals is within reach.
								</p>
							</div>

						</div>
					</div>

				</div>
			</div>
		</div>
	</section>
	)
}

export default AboutUs
