import React, {useState, useEffect} from "react"


function ExploreStates(){
	const cityList = ["Washington", "Texas", "California", "Georgia", "New York", "Massachusetts"]
	const [currentIndex, setCurrentIndex] = useState("0")
	useEffect(()=>{
		const intervalId = setInterval(()=>{
			setCurrentIndex(prev => (prev +1) % cityList.length)
		}, 1000)

		return () => clearInterval(intervalId)
	}, [cityList.length])

	return(
	<section className="find-your-state h-[50vh] bg-black w-full  my-12">
		<div className="w-full h-full  py-4 bg-blend-multiply bg-[#00000069]">
			<h3 className="text-white text-lg text-center mt-4 py-2 font-bold">Find Your State</h3>
			<h2 className="text-slate-100 font-medium text-xl text-center karla ">Properties in</h2>
			<h1 className="text-5xl md:text-7xl text-white text-center font-bold my-2 py-2 tracking-wider drop-shadow-lg" id="cityName">{cityList[currentIndex]}</h1>
			<div className="mx-auto my-4 w-[40rem] max-w-[90%] bg-slate-50 rounded-md shadow-sm flex justify-between overflow-hidden">
				<input type="text" name="state" id="stateField" placeholder="Enter State Name" className="bg-transparent w-[80%] px-8 py-2 " />
				
				<button className="inline bg-green-700 text-slate-50 w-[19%] py-2 hover:bg-green-900  border-0">Search</button>
			</div>
		</div>
	</section>
	)
}

export default ExploreStates;