import React from "react";

import About2 from '../../images/about/about2.png'
import About4 from '../../images/about/about4.png'

function Guide(){
	return(
		<section class="about-section lg:pt-[55px]">
            <div class="container">
				
                <div class="grid grid-cols-12 gap-6 items-center my-8 py-8 ">
                    <div class="col-span-12 lg:col-span-6">
                        <span class="text-secondary text-tiny inline-block mb-2">Guide To Buy a Home</span>
                        <h2 class="font-lora text-primary text-[24px] sm:text-[30px] leading-[1.277] xl:text-xl capitalize mb-5 lg:mb-16 font-medium max-w-[500px]">Why you should buy Your dream home from us<span class="sp-brand">?</span></h2>
                        <div class="scene" data-relative-input="true" style={{transform: 'translate3d(0px, 0px, 0px) rotate(0.0001deg)',transformStyle: 'preserve-3d',backfaceVisibility: 'hidden',position: 'relative',pointerEvents: 'none'}}>
                            <img data-depth="0.1" src={About2} class="rounded-xl" loading="lazy" width="729" height="663" alt="about Image" style={ {transform: 'translate3d(-1px, 13.7px, 0px)', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', position: 'relative', display: 'block', left: '0', top: '0'}} />
                        </div>
                    </div>
                    <div class="col-span-12 lg:col-span-6 ">
                        <p class="">
                            Finding a serious seller. Overcoming the documentation obstacles. 
                            Dealing with clever agents. There are far too many challenges that make 
                            the entire process of buying burdensome and a waste of your precious time.
                        </p>

                        <div class="-mb-10 mt-12 xl:mt-[70px] 2xl:mt-[100px]">
                            <div class="flex flex-wrap mb-5 lg:mb-10">
                                
                                <div class="flex-1">
                                    
                                    <p class="max-w-[448px]">
                                        And when everything is working fine, the ever-increasing 
                                        debt rears its ugly head, thus increasing your worries and making you lose your mind.
                                    </p>
                                </div>
                            </div>
                            <div class="flex flex-wrap mb-5 lg:mb-10">
                                
                                <div class="flex-1">
                                    
                                    <p class="max-w-[448px]">
                                        We totally get the frustration, and that is why we are bringing out our unrivaled professional 
                                        expertise to make your buying process as smooth as possible.
                                    </p>
                                </div>

                            </div>
                            <div class="flex flex-wrap mb-5 lg:mb-10">
                                <div class="flex-1">
                                    
                                    <p class="max-w-[448px]">
                                        With Oren Access, you will not only learn the ins and outs of the entire buying process 
                                        but also prepare for all the requirements ahead.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="grid grid-cols-12 gap-6 items-center my-8  py-8 ">
                    <div class="col-span-12 lg:col-span-6 lg:pl-[70px]">
                        <div class="mb-10 mt-12 ">
                             <div class="flex flex-wrap">
                                <div class="flex-1">
                                    <p class="max-w-[448px]">
                                        ✔  How to get the pre-approval for buying your property.
                                    </p>
                                </div>
                            </div>
                            <div class="flex flex-wrap">
                                <div class="flex-1">
                                    <p class="max-w-[448px]">
                                        ✔  How to find a trustworthy real estate agent.
                                    </p>
                                </div>
                            </div>

                            <div class="flex flex-wrap">
                                <div class="flex-1">
                                    <p class="max-w-[448px]">
                                        ✔  How to find a lawyer who can help you with legal obligations.
                                    </p>
                                </div>
                            </div>

                            <div class="flex flex-wrap">    
                                <div class="flex-1">
                                    <p class="max-w-[448px]">
                                        ✔  How to find a professional home inspector who can assess the quality of the property.
                                    </p>
                                </div>
                            </div>

                            <div class="flex flex-wrap ">
                                <div class="flex-1">
                                    <p class="max-w-[448px]">
                                        For us at Oren Access, navigating you through your buying journey is more than a job –it’s a passion.
                                        The agents you select will stay with you every step of the way and leave no stone unturned to ensure that you can buy your dream property without any disruptions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 lg:col-span-6">
                        <span class="text-secondary text-tiny inline-block mb-2">Be Guided</span>
                        <h2 class="font-lora text-primary text-[24px] sm:text-[30px] leading-[1.277] xl:text-xl capitalize mb-5 lg:mb-16 font-medium max-w-[500px]">We will guide you on<span class="sp-brand">:</span></h2>
                        <div class="scene" data-relative-input="true" style={{transform: 'translate3d(0px, 0px, 0px) rotate(0.0001deg)',transformStyle: 'preserve-3d',backfaceVisibility: 'hidden',position: 'relative',pointerEvents: 'none'}}>
                            <img data-depth="0.1" src={About4} class="rounded-xl" loading="lazy" width="729" height="663" alt="about Image" style={{transform: 'translate3d(-1px, 13.7px, 0px)', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', position: 'relative', display: 'block', left: '0px', top: '0px'}}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
	)
}

export default Guide;