import React from 'react';
import Swiper from 'react-id-swiper';


import Home1 from '../../images/hero/hernan.png';
import Home2 from '../../images/hero/home-2.png';

function HeroSection() {
  const params = {
    loop: true,
    speed: 600,
    autoplay: false,
    lazy: true,
    spaceBetween: 0,
    effect: 'fade',
    fadeEffect: { crossFade: true },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: false,
  };

  return (
    <section className="bg-[#FFFFFF] relative before:absolute before:w-1/2 lg:before:w-[calc(50%_-_35px)] xl:before:w-[calc(50%_-_65px)] before:h-full before:top-0 before:right-0 before:content-[''] before:bg-[#E8F1FF] pt-[130px] lg:pt-[80px] xl:pt-[0px] mb-[70px] lg:mb-[0px]">
        <div className="hero-slider overflow-hidden">
            <Swiper {...params}>
                <div className="swiper-slide lg:h-[700px] xl:h-[950px] xs:h-[auto] flex flex-wrap items-center">
                    <div className="container">
                        <div className="grid grid-cols-12">
                            <div className="col-span-12 lg:col-span-5 xl:col-span-6">
                                <div className="slider-content max-w-[560px]">
                                    <h1 className="font-lora sp-brand text-[36px] sm:text-[50px] md:text-[68px] lg:text-[50px] leading-tight xl:text-2xl title font-medium">
                                        <span>
                                            <span className=" relative before:content-[''] before:absolute before:w-full before:h-[3px] before:bg-primary before:bottom-1 sm:before:bottom-2 md:before:bottom-3 lg:before:bottom-2 xl:before:bottom-3 before:left-0">Oren Access</span>: 
                                            <span className=" text-black"> Revolutionizing <span className="sp-brand">Real Estate</span> Networking </span>
                                        </span>
                                    </h1>
                                    <p className="text-base text-[#494949] mt-5 mb-8 text max-w-[570px]">Discover a dynamic platform designed to transform how professionals connect, collaborate, and thrive in the real estate industry.</p>
                                    <div className="inline-block mb-[60px] hero_btn">
                                        <a href="" className="before:rounded-md before:block before:absolute before:left-auto before:right-0 before:inset-y-0 before:-z-[1] before:bg-secondary before:w-0 hover:before:w-full hover:before:left-0 hover:before:right-auto before:transition-all leading-none px-[20px] py-[15px] capitalize font-medium text-white text-[14px] xl:text-[16px] relative after:block after:absolute after:inset-0 after:-z-[2] after:bg-primary after:rounded-md after:transition-all block">Contact us</a>
                                    </div>
                                    <ul className="flex flex-wrap list-none">
                                        <li className="block">
                                            <span className="font-lora text-secondary text-lg">
                                                <span>20</span>
                                                <span>k+</span>
                                            </span>
                                            <p>Properties</p>
                                        </li>
                                        <li className="block pl-[25px] sm:pl-[40px] md:pl-[60px]">
                                            <span className="font-lora text-secondary text-lg">
                                                <span>12</span>
                                                <span>k+</span>
                                            </span>
                                            <p>Customers</p>
                                        </li>
                                        <li className="block pl-[25px] sm:pl-[40px] md:pl-[60px]">
                                            <span className="font-lora text-secondary text-lg">
                                                <span>160</span>
                                                <span>+</span>
                                            </span>
                                            <p>Awards Win</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-span-12 lg:col-span-7 xl:col-span-6">
                                <div className="relative mt-10 md:mt-0 lg:absolute lg:right-0 lg:bottom-0">
                                    <img className="hero_image lg:max-w-[550px] xl:max-w-[650px] 2xl:max-w-[750px] 3xl:max-w-[866px]" src={Home1} width="866" height="879" alt="hero image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="swiper-slide lg:h-[700px] xl:h-[950px] xs:h-[auto] flex flex-wrap items-center">
                    <div className="container">
                        <div className="grid grid-cols-12">
                            <div className="col-span-12 lg:col-span-5 xl:col-span-6">
                                <div className="slider-content max-w-[560px]">
                                    <h1 className="font-lora text-primary text-[36px] sm:text-[50px] md:text-[68px] lg:text-[50px] leading-tight xl:text-2xl title font-medium">
                                        <span>
                                            <span className=" relative before:content-[''] before:absolute before:w-full before:h-[3px] before:bg-primary before:bottom-1 sm:before:bottom-2 md:before:bottom-3 lg:before:bottom-2 xl:before:bottom-3 before:left-0">Oren Access</span>: 
                                            <span className=" text-black"> Revolutionizing <span className="sp-brand">Real Estate</span> Networking </span>
                                        </span>
                                    </h1>
                                    <p className="text-base text-[#494949] mt-5 mb-8 text">A huge number of properties are available here for buy and sell, and you can find co-living properties too, offering lots of opportunities.</p>
                                    <div className="inline-block mb-[60px] hero_btn">
                                        <a href="" className="before:rounded-md before:block before:absolute before:left-auto before:right-0 before:inset-y-0 before:-z-[1] before:bg-secondary before:w-0 hover:before:w-full hover:before:left-0 hover:before:right-auto before:transition-all leading-none px-[20px] py-[15px] capitalize font-medium text-white text-[14px] xl:text-[16px] relative after:block after:absolute after:inset-0 after:-z-[2] after:bg-primary after:rounded-md after:transition-all block">Contact us</a>
                                    </div>
                                    <ul className="flex flex-wrap list-none">
                                        <li className="block">
                                            <span className="font-lora text-secondary text-lg">
                                                <span>20</span>
                                                <span>k+</span>
                                            </span>
                                            <p>Properties</p>
                                        </li>
                                        <li className="block pl-[25px] sm:pl-[40px] md:pl-[60px]">
                                            <span className="font-lora text-secondary text-lg">
                                                <span>12</span>
                                                <span>k+</span>
                                            </span>
                                            <p>Customers</p>
                                        </li>
                                        <li className="block pl-[25px] sm:pl-[40px] md:pl-[60px]">
                                            <span className="font-lora text-secondary text-lg">
                                                <span>160</span>
                                                <span>+</span>
                                            </span>
                                            <p>Awards Win</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-span-12 lg:col-span-7 xl:col-span-6">
                                <div className="relative mt-10 md:mt-0 lg:absolute lg:right-0 lg:bottom-0">
                                    <img className="hero_image lg:max-w-[550px] xl:max-w-[650px] 2xl:max-w-[750px] 3xl:max-w-[866px]" src={Home2} width="906" height="950" alt="hero image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Swiper>
        </div>
    </section>

  );
}

export default HeroSection;
