import React from "react";

import { Link, NavLink, useLocation, useNavigate} from "react-router-dom";


import Logo from '../images/logo/logo.png';
import Avatar from '../images/user/avater.png';

function Header(){
    const location = useLocation();
    const navigate = useNavigate();
	return (
        <header id="sticky-header" className={`absolute left-0 top-[15px] lg:top-[30px] xl:top-[45px] w-full z-10 py-5 ${location.pathname === '/' ? 'bg-transparent' : 'bg-white' }  `}>
            <div className="container">
                <div className="grid grid-cols-12">
                    <div className="col-span-12">
                        <div className="flex flex-wrap items-center justify-between">
                            <a href="" className="block">
                                <img className="w-full h-full" src={Logo} loading="lazy" width="99" height="46" alt="brand logo" />
                            </a>
                            <nav className="flex flex-wrap items-center">
                                <ul className="hidden lg:flex flex-wrap items-center font-lora text-[16px] xl:text-[18px] leading-none text-black">
                                    <li className="mr-7 xl:mr-[40px] relative group py-[20px]">
                                        <Link to='/' className="transition-all hover:text-secondary">Home</Link>
                                    </li>

                                    <li className="mr-7 xl:mr-[40px] relative group py-[20px]">
                                        <NavLink to='/buyer' className="transition-all hover:text-secondary">Buyer</NavLink>
                                        <ul className="list-none bg-white drop-shadow-[0px_6px_10px_rgba(0,0,0,0.2)] rounded-[12px] flex flex-wrap w-[530px] absolute top-[120%] left-1/2 translate-x-[-40%] xl:translate-x-[-45%] transition-all
                                            group-hover:top-[100%] invisible group-hover:visible opacity-0 group-hover:opacity-100 px-[40px] py-[45px]" >
                                            <li className="mr-[70px]">
                                                <ul>
                                                    
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <NavLink to='buyer' className="font-lora text-[14px] hover:text-secondary">All Homes</NavLink>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">Commercial Properties</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">For Sale By Owner </a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">Land</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">Shortsale</a>
                                                    </li>

                                                </ul>
                                            </li>
                                            <li className="mr-[70px]">
                                                <ul>
                                                    
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="properties-list-left-side-bar.html" className="font-lora text-[14px] hover:text-secondary">Foreclosures</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="properties-list-right-side-bar.html" className="font-lora text-[14px] hover:text-secondary">Open Houses</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="properties-list-right-side-bar.html" className="font-lora text-[14px] hover:text-secondary">Coming Soon</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="properties-list-right-side-bar.html" className="font-lora text-[14px] hover:text-secondary">Off Market Properties</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="properties-list-right-side-bar.html" className="font-lora text-[14px] hover:text-secondary">International Properties</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="mr-7 xl:mr-[40px] relative group py-[20px]">
                                        <NavLink to="/seller" className="transition-all hover:text-secondary">Seller</NavLink>
                                        <ul className="list-none bg-white drop-shadow-[0px_6px_10px_rgba(0,0,0,0.2)] rounded-[12px] flex flex-wrap w-[310px] absolute top-[120%] left-1/2 translate-x-[-40%] xl:translate-x-[-45%] transition-all
                                            group-hover:top-[100%] invisible group-hover:visible opacity-0 group-hover:opacity-100 px-[40px] py-[45px]" >
                                            <li className="mr-[70px]">
                                                <ul>
                                                    
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">All Homes</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">Investors</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">Commercials Properties</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">For Sale By Owner </a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">Land</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">International Properties</a>
                                                    </li>

                                                </ul>
                                            </li>
                                            
                                        </ul>
                                    </li>

                                    <li className="mr-7 xl:mr-[40px] relative group py-[20px]">
                                        <a href="" className="transition-all hover:text-secondary">Renter</a>
                                        <ul className="list-none bg-white drop-shadow-[0px_6px_10px_rgba(0,0,0,0.2)] rounded-[12px] flex flex-wrap w-[470px] absolute top-[120%] left-1/2 translate-x-[-40%] xl:translate-x-[-45%] transition-all
                                            group-hover:top-[100%] invisible group-hover:visible opacity-0 group-hover:opacity-100 px-[40px] py-[45px]" >
                                            <li className="mr-[70px]">
                                                <ul>
                                                    
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">Apartment for Rent</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">Rental Buildings</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">House for Rent</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">Short Term Rental</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="mr-[70px]">
                                                <ul>
                                                    
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">Rooms For Rent</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">High End Rental</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">Land Rental</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">All Rental Listings</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="mr-7 xl:mr-[40px] relative group py-[20px]">
                                        <NavLink to="/professional/real-estate-agent" className="transition-all hover:text-secondary">Professionals</NavLink >
                                        <ul className="list-none bg-white drop-shadow-[0px_6px_10px_rgba(0,0,0,0.2)] rounded-[12px] flex flex-wrap w-[930px] absolute top-[120%] left-1/2 translate-x-[-40%] xl:translate-x-[-45%] transition-all
                                            group-hover:top-[100%] invisible group-hover:visible opacity-0 group-hover:opacity-100 px-[40px] py-[45px] ">
                                            
                                            <li className="mr-14">
                                                <ul>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Accountants</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Appraisal Companies</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Carpenter</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Computer Technician</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Credit Report Company</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Construction Companies</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Developer Contractor</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Licensed Electricians</a></li>
                                    
                                                </ul>
                                            </li>
                                            
                                            <li className="mr-14">
                                                <ul>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Handyman</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><NavLink to="/professional/home-inspector" className="font-lora text-[14px] hover:text-secondary">Home Inspector</NavLink></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Home Insurance Company</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Interior Designer</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Real Estate Attorney</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Hard Money Lender</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Landscape Company</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Mortgage Lender</a></li>
                                    
                                                </ul>
                                            </li>
                                            
                                            <li className="mr-14">
                                                <ul>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Mortgage Banker</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Moving Companies</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Notary Public</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Photographer</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Professional Painter</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Licensed Plumber</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Real Estate Schools</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Real Estate Broker</a></li>
                                    
                                                </ul>
                                            </li>
                                            <li className="mr-14">
                                                <ul>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Real Estate Coaches</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Real Estate Instructors</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Real Estate Investors</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Real Estate Salesperson</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Survey Company</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Title Company</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Influencer</a></li>
                                                    <li className="mb-[1rem] last:mb-0"><a href="" className="font-lora text-[14px] hover:text-secondary">Exterminator</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    
                                    
                                    <li className="mr-7 xl:mr-[40px] relative group py-[20px]">
                                        <a href="" className="transition-all hover:text-secondary">Investor</a>
                                        <ul className="list-none bg-white drop-shadow-[0px_6px_10px_rgba(0,0,0,0.2)] rounded-[12px] flex flex-wrap w-[310px] absolute top-[120%] left-1/2 translate-x-[-40%] xl:translate-x-[-45%] transition-all
                                            group-hover:top-[100%] invisible group-hover:visible opacity-0 group-hover:opacity-100 px-[40px] py-[45px]" >
                                            <li className="mr-[70px]">
                                                <ul>
                                                    
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">Sell to an Investor</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">Buy from an Investor</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">Off Market Properties</a>
                                                    </li>
                                                    <li className="mb-[1rem] last:mb-0">
                                                        <a href="" className="font-lora text-[14px] hover:text-secondary">All Investors Properties</a>
                                                    </li>

                                                </ul>
                                            </li>
                                            
                                        </ul>
                                    </li>
                                    
                                    <li className="mr-7 xl:mr-[40px] relative group py-[20px]">
										<a href="" className="transition-all hover:text-secondary">Community</a>
										<ul className="list-none bg-white drop-shadow-[0px_6px_10px_rgba(0,0,0,0.2)] rounded-[12px] flex flex-wrap w-[310px] absolute top-[120%] left-1/2 translate-x-[-40%] xl:translate-x-[-45%] transition-all
											group-hover:top-[100%] invisible group-hover:visible opacity-0 group-hover:opacity-100 px-[40px] py-[45px]" >
											<li className="mr-[70px]">
												<ul>
													<li className="mb-[1rem] last:mb-0">
														<a href="" className="font-lora text-[14px] hover:text-secondary">Blogs</a>
													</li>
													<li className="mb-[1rem] last:mb-0">
														<a href="" className="font-lora text-[14px] hover:text-secondary">Forum</a>
													</li>
													<li className="mb-[1rem] last:mb-0">
														<a href="" className="font-lora text-[14px] hover:text-secondary">Announcements</a>
													</li>
													<li className="mb-[1rem] last:mb-0">
														<a href="" className="font-lora text-[14px] hover:text-secondary">News</a>
													</li>
                                                    <li className="mb-[1rem] last:mb-0">
														<a href="" className="font-lora text-[14px] hover:text-secondary">Contact</a>
													</li>

												</ul>
											</li>
										</ul>
									</li>
                                </ul>

                                <ul className="flex flex-wrap items-center">
                                    <li className="sm:mr-5 xl:mr-[20px] relative group hidden">
                                        <a href="#">
                                            <img src={Avatar} loading="lazy" width="62" height="62" alt="avater" />
                                        </a>

                                        <ul className="list-none bg-white drop-shadow-[0px_6px_10px_rgba(0,0,0,0.2)] rounded-[12px] flex flex-wrap flex-col w-[180px] absolute top-[120%] sm:left-1/2 sm:-translate-x-1/2 transition-all
                                            group-hover:top-[60px] invisible group-hover:visible opacity-0 group-hover:opacity-100 right-0
                                            
                                            ">
                                            <li className="border-b border-dashed border-primary border-opacity-40 last:border-b-0 hover:border-solid transition-all">
                                                <a href="" className="font-lora leading-[1.571] text-[14px] text-primary p-[10px] capitalize block transition-all hover:bg-primary hover:text-white text-center my-[-1px] rounded-t-[12px]">login</a>
                                            </li>

                                            <li className="border-b border-dashed border-primary border-opacity-40 last:border-b-0 hover:border-solid transition-all">
                                                <a href="" className="font-lora leading-[1.571] text-[14px] text-primary p-[10px] capitalize block transition-all hover:bg-primary hover:text-white text-center my-[-1px] rounded-b-[12px]">register</a>
                                            </li>
                                        </ul>

                                    </li>

                                    <li>
                                        <button type="submit" className="text-white block mx-4 font-medium text-[16px] leading-none tracking-[0.02em] bg-secondary py-[12px] px-[20px] rounded-[10px] hover:bg-white hover:text-primary transition-all border-secondary border-2" onClick={ () => navigate('/login')}>Login</button>
                                    </li>
                                    
                                    <li className="">
                                        <a href="" className="before:rounded-md before:block before:absolute before:left-auto before:right-0 before:inset-y-0 before:-z-[1] before:bg-secondary before:w-0 hover:before:w-full hover:before:left-0 hover:before:right-auto before:transition-all leading-none px-[20px] py-[15px] capitalize font-medium text-white hidden sm:block text-[14px] xl:text-[16px] relative after:block after:absolute after:inset-0 after:-z-[2] after:bg-primary after:rounded-md after:transition-all">Add Property</a>
                                    </li>
                                    <li className="ml-2 sm:ml-5 lg:hidden">
                                        <a href="#offcanvas-mobile-menu" className="offcanvas-toggle flex text-[#016450] hover:text-secondary">
                                            <svg width="24" height="24" className="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                <path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z" />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
	);
}

export default Header;