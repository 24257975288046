
function Reviews(){
	return(
		<section className="popular-properties py-[80px] ">
			<div className="container">
				<div className="grid grid-cols-12 mb-[-30px] gap-[30px] xl:gap-[50px]">
					<div className="col-span-12 md:col-span-8 ">
						<h2 className="font-lora text-primary text-[24px] sm:text-[28px] capitalize font-medium">
							Reviews
							<span className="text-secondary">.</span>
						</h2>

						<ul className="mt-[50px] lg:mt-[70px]">
							<li className="flex flex-wrap mb-[55px]  last:mb-0">
								<img className="self-start mr-[35px] border border-primary rounded-[26px]" src="/images/commentor/1.png" width="78" height="66" loading="lazy" alt="image" />
								<div className="flex-1">
									<h4 className="text-primary font-lora text-[18px] leading-none mb-[5px]">
										Stela Flemming,
										<span className="text-[14px] text-[#494949]">20 Jan, 2022</span>
									</h4>
									<p>Sliva do a great job to find the perfect home. It’s very easy for every one to buy, sell or rent property we belive they continure their great service and appriciat.</p>
									<p className="mt-[8px]">
										<a href="#" className="inline-block mr-[10px] hover:text-secondary">Report A Problem</a>
									</p>
								</div>
							</li>
							<li className="flex flex-wrap mb-[55px]  last:mb-0">
								<img className="self-start mr-[35px] border border-primary rounded-[26px]" src="/images/commentor/2.png" width="78" height="66" loading="lazy" alt="image" />
								<div className="flex-1">
									<h4 className="text-primary font-lora text-[18px] leading-none mb-[5px]">
										Shane Williamson,
										<span className="text-[14px] text-[#494949]">20 Jan, 2022</span>
									</h4>
									<p>Sliva do a great job to find the perfect home. It’s very easy for every one to buy, sell or rent property we belive they continure their great service and appriciat.</p>
									<p className="mt-[8px]">
										<a href="#" className="inline-block mr-[10px] hover:text-secondary">Report A Problem</a>
									</p>
								</div>
							</li>
							<li className="flex flex-wrap mb-[55px]  last:mb-0">
								<img className="self-start mr-[35px] border border-primary rounded-[26px]" src="/images/commentor/3.png" width="78" height="66" loading="lazy" alt="image" />
								<div className="flex-1">
									<h4 className="text-primary font-lora text-[18px] leading-none mb-[5px]">
										Shohel Buddy,
										<span className="text-[14px] text-[#494949]">20 Jan, 2022</span>
									</h4>
									<p>Sliva do a great job to find the perfect home. It’s very easy for every one to buy, sell or rent property we belive they continure their great service and appriciat.</p>
									<p className="mt-[8px]">
										<a href="#" className="inline-block mr-[10px] hover:text-secondary">Report A Problem</a>
									</p>
								</div>
							</li>
						</ul>

					</div>
					<div className="col-span-12 lg:col-span-4">
						<h2 className="font-lora text-primary text-[24px] sm:text-[28px] capitalize nt-[80px] lg:mt-[90px] font-medium">
							Write a Review<span className="text-secondary">.</span>
						</h2>
						<div className="mt-[60px]">
							<form action="#" className="grid grid-cols-12 gap-x-[20px] gap-y-[30px]">
								<div className="col-span-12 md:col-span-6">
									<input className="font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-[#1B2D40] border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)]" type="text" placeholder="First Name" />
								</div>

								<div className="col-span-12 md:col-span-6">
									<input className="font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-[#1B2D40] border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)]" type="text" placeholder="Last Name" />
								</div>

								<div className="col-span-12 md:col-span-6">
									<input className="font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-[#1B2D40] border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)]" type="text" placeholder="Phone number" />
								</div>

								<div className="col-span-12 md:col-span-6">
									<input className="font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-[#1B2D40] border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)]" type="email" placeholder="Email Address" />
								</div>

								<div className="col-span-12">
									<textarea className="h-[196px] font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-[#1B2D40] border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] resize-none" name="textarea" id="textarea" cols="30" rows="10" placeholder="Message"></textarea>
								</div>

								<div className="col-span-12">
									<button type="submit" className="before:rounded-md before:block before:absolute before:left-auto before:right-0 before:inset-y-0 before:-z-[1] before:bg-secondary before:w-0 hover:before:w-full hover:before:left-0 hover:before:right-auto before:transition-all leading-none px-[30px] py-[15px] capitalize font-medium text-white block text-[14px] xl:text-[16px] relative after:block after:absolute after:inset-0 after:-z-[2] after:bg-primary after:rounded-md after:transition-all">Review</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>

	)
}

export default Reviews