import HeaderTop from "../../HeaderTop"
import BreadCrumb from "../../BreadCrumb"
import FindAgent from "../FindAgent"
import TeamSection from "./TeamSection"
import FindLocalAgent from "./FindLocalAgent"
import TextDescription from "./TextDescription"


import BreadCrumbs from '../../data/breadcrumbs.json'
function RealEstateAgent(){
	const BreadCrumbData = BreadCrumbs.realEstateAgent;
	return(
		<>
			<HeaderTop />
			<BreadCrumb {...BreadCrumbData}/>
			<FindAgent />
			<TeamSection />
			<FindLocalAgent />
			<TextDescription />
		</>
	)
}

export default RealEstateAgent;