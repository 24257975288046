import TeamMember from "./TeamMember"

import TeamMembers from "../../data/teamMembers.json"
function TeamSection(){
	
	return(
		<section className="team-section pb-10 ">
			<div className="container">
				<div className="text-center px-12 my-10 py-10">
					<h1 className="text-3xl py-4">Real Estate Agents in Massachusetts, US</h1>
					<p>Unparalleled network, local knowledge: Our vast pool of top agents provides personalized guidance every step of the way.</p>
				</div>
				<div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-x-[30px] -mb-[30px]">
					
				{TeamMembers.map((member, index) => (
					<TeamMember key={index} member={member} />
				))}
					
				</div>
				<button id="mc-submit" type="submit" className="text-white block mx-auto my-10 font-medium text-[16px] leading-none tracking-[0.02em] bg-secondary py-[12px] px-[20px] rounded-[10px] hover:bg-white hover:text-primary transition-all border-secondary border-2">Show More</button>
			</div>
		</section>
	)
}


export default TeamSection