import React,{ useState } from "react";

import Setting from '../../images/icon/setting.svg';

import Buy from './AdvancedSearch/Buy'
import Sell from './AdvancedSearch/Sell'
import Rental from './AdvancedSearch/Rental'
import Professional from './AdvancedSearch/Professional'
import Investor from './AdvancedSearch/Investor'
function AdvancedSearchTab(){
    const [activeTab, setActiveTab] = useState('buy')
    const [foldedTab, setFoldedTab] = useState(true)

    const handleTabChange = (tab) => {
        setActiveTab(tab)
        setFoldedTab(true)
    }

    const handleFoldedChange = () => {
        setFoldedTab(!foldedTab)
    }

	return (
        <div className="mt-[80px] lg:mt-[120px] xl:mt-[-160px] relative z-[2] pl-[40px] lg:pl-[50px] xl:pl-[0px]">
            <div className="container">
                <div className="grid grid-cols-12">
                    <div className="col-span-12 relative">
                        <ul className="tab-nav search-tab inline-flex px-[15px] sm:px-[30px] py-[22px] border-l border-t border-r border-solid border-[#016450] border-opacity-25 rounded-tl-[15px] rounded-tr-[15px] bg-white">
                            <li className={`mr-[5px] sm:mr-[10px] md:mr-[46px] my-1 ${activeTab === 'buy' ? 'active' : ''} `} onClick={() => handleTabChange('buy')}>
                                <button className={`font-lora leading-none px-[5px] sm:px-[10px] capitalize text-primary transition-all text-base xl:text-[22px] before:absolute before:left-auto before:right-0 before:bottom-[-32px] before:w-0 before:h-[2px] before:content-[''] before:bg-secondary relative before:transition-all ease-out ${activeTab === 'buy' ? 'acitve' : null}`}>buy</button>
                            </li>
                            <li className={`mr-[5px] sm:mr-[10px] md:mr-[46px] my-1 ${activeTab === 'sell' ? 'active' : ''} `} onClick={() => handleTabChange('sell')} >
                                <button className="font-lora leading-none px-[5px] sm:px-[10px] capitalize text-primary transition-all text-base xl:text-[22px] before:absolute before:left-auto before:right-0 before:bottom-[-32px] before:w-0 before:h-[2px] before:content-[''] before:bg-secondary relative before:transition-all ease-out">sell</button>
                            </li>
                            <li  className={`mr-[5px] sm:mr-[10px] md:mr-[46px] my-1 ${activeTab === 'rental' ? 'active' : ''} `} onClick={() => handleTabChange('rental')}>
                                <button className="font-lora leading-none px-[5px] sm:px-[10px] capitalize text-primary transition-all text-base xl:text-[22px] before:absolute before:left-auto before:right-0 before:bottom-[-32px] before:w-0 before:h-[2px] before:content-[''] before:bg-secondary relative before:transition-all ease-out">Rental</button>
                            </li>
                            <li  className={`mr-[5px] sm:mr-[10px] md:mr-[46px] my-1 ${activeTab === 'professional' ? 'active' : ''} `} onClick={() => handleTabChange('professional')}>
                                <button className="font-lora leading-none px-[5px] sm:px-[10px] capitalize text-primary transition-all text-base xl:text-[22px] before:absolute before:left-auto before:right-0 before:bottom-[-32px] before:w-0 before:h-[2px] before:content-[''] before:bg-secondary relative before:transition-all ease-out">Professional</button>
                            </li>
                            <li  className={`mr-[5px] sm:mr-[10px] md:mr-[46px] my-1 ${activeTab === 'investor' ? 'active' : ''} `} onClick={() => handleTabChange('investor')}>
                                <button className="font-lora leading-none px-[5px] sm:px-[10px] capitalize text-primary transition-all text-base xl:text-[22px] before:absolute before:left-auto before:right-0 before:bottom-[-32px] before:w-0 before:h-[2px] before:content-[''] before:bg-secondary relative before:transition-all ease-out">Investor</button>
                            </li>
                        </ul>

                        <button className="tab-toggle-btn px-[10px] py-[15px] absolute bottom-[-56px] left-[-45px] border-l border-t border-b border-solid border-[#016450] bg-white text-primary border-opacity-25 rounded-tl-[10px] rounded-bl-[10px]" aria-label="svg icon" onClick={() => handleFoldedChange()}>
                            <img src={Setting} />
                        </button>
                    </div>
                    <div className="col-span-12 selectricc-border-none">
                        {activeTab === 'buy' && <Buy foldedTab={foldedTab} />}
                        {activeTab === 'sell' && <Sell foldedTab={foldedTab} />}
                        {activeTab === 'rental' && <Rental foldedTab={foldedTab} />}
                        {activeTab === 'professional' && <Professional foldedTab={foldedTab} />}
                        {activeTab === 'investor' && <Investor foldedTab={foldedTab} />}
                    </div>
                </div>
            </div>
        </div>
	);
}

export default AdvancedSearchTab;