import HeaderTop from "../../HeaderTop"
import BreadCrumb from "../../BreadCrumb"

import BreadCrumbs from "../../data/breadcrumbs.json"
import FindAgent from "../FindAgent"
import TeamSection from "./TeamSection"
function HomeInspector(){
	const BreadCrumbData = BreadCrumbs.homeInspector

	return(
		<>
			<HeaderTop />
			<BreadCrumb {...BreadCrumbData}/>
			<FindAgent />
			<TeamSection />
		</>
	)
}

export default HomeInspector