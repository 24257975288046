
function FeaturesOffered(){
	return(
		<section className=" pb-[80px] ">
            <div className="container">
                <div className="grid grid-cols-12">
                    <div className="col-span-12">
                        <div className="mb-[30px] lg:mb-[60px] text-center">
                            <span className="text-secondary text-tiny inline-block mb-2">Features Offered</span>
                            <h2 className="font-lora text-primary text-[24px] sm:text-[30px] xl:text-xl font-medium">
                                Other Features Offered by Oren Access<span className="sp-brand">.</span></h2>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3  gap-[30px]">

                    <div className="relative group">
                        <a href="" className="block">
                            
                            <div className="drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] hover:drop-shadow-[0px_8px_20px_rgba(0,0,0,0.15)] bg-[#FFFDFC] rounded-[0px_0px_6px_6px] px-[25px] py-[25px]">
                                <h3 className="font-lora font-normal text-[24px] xl:text-lg text-primary group-hover:text-secondary transition-all mb-[5px]">For Sale by Owner<span className="sp-brand group-hover:text-primary">.</span> </h3>
                                <p className="font-light text-tiny min-h-60">
                                    Oren Access also allows you to list your property on our platform without an agent/ realtor. 
                                    However, for optimum results, it is always recommended to pass on the burden to a professional agent who can overcome the complicated selling challenges.
                                </p>
                            </div>
                        </a>
                    </div>

                    <div className="relative group">
                        <a href="" className="block">
                            
                            <div className="drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] hover:drop-shadow-[0px_8px_20px_rgba(0,0,0,0.15)] bg-[#FFFDFC] rounded-[0px_0px_6px_6px] px-[25px] py-[25px]">
                                <h3 className="font-lora font-normal text-[24px] xl:text-lg text-primary group-hover:text-secondary transition-all mb-[5px]">Find Rentals<span className="sp-brand group-hover:text-primary">.</span> </h3>
                                <p className="font-light text-tiny min-h-60">
                                    Seeking to rent out your property and want to meet with someone who would be interested in your offering? Oren Access has got you covered. 
                                    Check our extensive rental listings, contact your preferred customer, and rent out your property without ado. There is no area restriction with Oren Access.
                                </p>
                            </div>
                        </a>
                    </div>

                    <div className="relative group ">
                        <a href="" className="block">
                            
                            <div className="drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] hover:drop-shadow-[0px_8px_20px_rgba(0,0,0,0.15)] bg-[#FFFDFC] rounded-[0px_0px_6px_6px] px-[25px] py-[25px]">
                                <h3 className="font-lora font-normal text-[24px] xl:text-lg text-primary group-hover:text-secondary transition-all mb-[5px]">Real Estate Investors<span className="sp-brand group-hover:text-primary">.</span> </h3>
                                <p className="font-light text-tiny min-h-60">
                                    At Oren Access, we’re passionate about improving your return on investment, and that passion can be felt in every investment opportunity we offer. 
                                    List your properties on our platform, attract potential clients from all across the internet, and skyrocket your profit like never before.
                                </p>
                            </div>
                        </a>
                    </div>

                    
                </div>
            </div>
        </section>
	)
}

export default FeaturesOffered;