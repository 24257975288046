import React from "react";
import Camera from '../images/icon/camera.png';
import Video from '../images/icon/video.png';
import Area from '../images/buttons/area.svg';
import BedRoom from '../images/buttons/bedroom.svg';
import BathRoom from '../images/buttons/bathroom.svg';
import Parking from '../images/buttons/parking.svg';
import Share from '../images/buttons/share.svg';
import Heart from '../images/buttons/heart.svg';

const importImage = (imageName) => {
    try {
      return require(`../images/${imageName}`);
    } catch (error) {
      console.error(`Failed to load image: ${imageName}`, error);
      return null; 
    }
  };

function PropertiesCard({ data }) {
    const imageSrc = `/images/properties/${data.image}`

    return (
        <div className="swiper-slide">
            <div className="overflow-hidden rounded-md drop-shadow-[0px_0px_5px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center transition-all duration-300 hover:-translate-y-[10px] property-card">
                <div className="relative">
                    <a href="" className="block">
                        <img src={imageSrc} className="w-full h-full" loading="lazy" width="370" height="266" alt={data.title} />
                    </a>
                    <div className="flex flex-wrap flex-col absolute top-5 right-5">
                        <button className="flex flex-wrap items-center bg-[rgb(11,44,61,0.8)] p-[5px] rounded-[2px] text-white mb-[5px] text-xs">
                            <img className="mr-1" src={Camera} loading="lazy" width="13" height="10" alt="camera icon" />{data.photosCount}
                        </button>
                        <button className="flex flex-wrap items-center bg-[rgb(11,44,61,0.8)] p-[5px] rounded-[2px] text-white text-xs">
                            <img className="mr-1" src={Video} loading="lazy" width="14" height="10" alt="camera icon" />{data.videosCount}
                        </button>
                    </div>
                    <span className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize">{data.status}</span>
                </div>

                <div className="py-[20px] px-[20px] text-left">
                    <h3><a href="" className="font-lora leading-tight text-[22px] xl:text-[26px] text-primary hover:text-secondary transition-all font-medium truncate block">{data.title}</a></h3>
                    <h4><a href="" className="font-light text-[14px] leading-[1.75] underline">{data.address}</a></h4>

                    <ul className="flex flex-wrap items-center justify-between text-[12px] mt-[10px] mb-[15px] pb-[10px] border-b border-[#E0E0E0]">
                        <li className="flex flex-wrap items-center pr-[25px] sm:pr-[5px] md:pr-[25px] border-r border-[#E0DEDE]">
                            <img src={Area} alt="area" className="mr-[5px]" />
                            <span>{data.area}</span>
                        </li>
                        <li className="flex flex-wrap items-center pr-[25px] sm:pr-[5px] md:pr-[25px] border-r border-[#E0DEDE]">
                            <img src={BedRoom} alt="bedroom" className="mr-[5px]" />
                            <span>{data.bedrooms}</span>
                        </li>
                        <li className="flex flex-wrap items-center pr-[25px] sm:pr-[5px] md:pr-[25px] border-r border-[#E0DEDE]">
                            <img src={BathRoom} alt="bathroom" className="mr-[5px]" />
                            <span>{data.bathrooms}</span>
                        </li>
                        <li className="flex flex-wrap items-center pr-[25px] sm:pr-[5px] md:pr-[25px] border-r border-[#E0DEDE]">
                            <img src={Parking} alt="parking" className="mr-[5px]" />
                            <span>{data.parking}</span>
                        </li>
                    </ul>

                    <ul>
                        <li className="flex flex-wrap items-center justify-between">
                            <span className="font-lora text-base text-primary leading-none font-medium">Price: {data.price}</span>

                            <span className="flex flex-wrap items-center">
                                <button className="mr-[15px] text-[#9D9C9C] hover:text-secondary" aria-label="svg">
                                    <img src={Share} alt="share" />
                                </button>
                                <button className="text-[#9D9C9C] hover:text-secondary" aria-label="svg">
                                    <img src={Heart} alt="heart" />
                                </button>
                            </span>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    );
}

export default PropertiesCard;
