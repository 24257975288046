import React from "react";

function OffCanvasMenu(){
	return (
		<>
        <div className="offcanvas-overlay hidden fixed inset-0 bg-black opacity-50 z-50"></div>
{/*         <!-- offcanvas-overlay end --> */}
         
        {/* <!-- offcanvas-mobile-menu start --> */}
        <div id="offcanvas-mobile-menu" className="offcanvas left-0 transform -translate-x-full fixed font-normal text-sm top-0 z-50 h-screen w-[300px] transition-all ease-in-out duration-300 bg-white">

            <div className="py-12 pr-5 h-[100vh] overflow-y-auto">
                {/* <!-- close button start --> */}
                <button className="offcanvas-close text-primary text-[25px] w-10 h-10 absolute right-0 top-0 z-[1]" aria-label="offcanvas">x</button>
                {/* <!-- close button end --> */}

                {/* <!-- offcanvas-menu start --> */}

                <nav className="offcanvas-menu mr-[20px]">
                    <ul>
                        <li className="relative block border-b-primary border-b first:border-t first:border-t-primary">
                            <a href="index.html" className="block capitalize font-normal text-black hover:text-secondary text-base my-2 py-1 px-5">Home</a>
                        </li>
                        
                        <li className="relative block border-b-primary border-b">
                            <span className="menu-expand"></span>
                            <a href="buyers.html" className="block capitalize font-normal text-black hover:text-secondary text-base my-2 py-1 px-5">Buyer</a>
                            <ul className="offcanvas-submenu static top-auto hidden w-full visible opacity-100 capitalize" style={{display: 'none',}}>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">All Homes</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Commercial Properties</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">For Sale By Owner FSBO</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Land</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Shortsale</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Foreclosures</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Open Houses</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Coming Soon</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Off Market Properties</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">International Properties</a>
                                </li>
                            </ul>
                        </li>
                        
                        <li className="relative block border-b-primary border-b">
                            <span className="menu-expand"></span>
                            <a href="sellers.html" className="block capitalize font-normal text-black hover:text-secondary text-base my-2 py-1 px-5">Seller</a>
                            <ul className="offcanvas-submenu static top-auto hidden w-full visible opacity-100 capitalize" style={{display: 'none'}}>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">All Homes</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Investors</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Commercial Properties</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">For Sale By Owner FSBO</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Land</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">International Properties</a>
                                </li>
                            </ul>
                        </li>
                        
                        <li className="relative block border-b-primary border-b">
                            <span className="menu-expand"></span>
                            <a href="" className="block capitalize font-normal text-black hover:text-secondary text-base my-2 py-1 px-5">Renter</a>
                            <ul className="offcanvas-submenu static top-auto hidden w-full visible opacity-100 capitalize" style={{display: 'none'}}>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Apartment for Rent</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Rental Buildings</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">House for Rent</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Short Term Rental</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Rooms for Rent</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">High End Rental</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Land Rental</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">All Rental Listings</a>
                                </li>
                            </ul>
                        </li>
                        
                        <li className="relative block border-b-primary border-b">
                            <span className="menu-expand"></span>
                            <a href="" className="block capitalize font-normal text-black hover:text-secondary text-base my-2 py-1 px-5">Professionals</a>
                            <ul className="offcanvas-submenu static top-auto hidden w-full visible opacity-100 capitalize" style={{display: 'none'}}>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Find A Real Estate Agent</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Real estate lawyers</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Mortgage Lender</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Banks</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Title Companies</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Licensed Electricians</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Home inspectors</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Home Appraiser</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Survey Company</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Home Insurance</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Construction Company</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Carpenter</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Licensed Plumber</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Real estate schools</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Notaries public</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Photographers</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Landscape Company</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Moving Company</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Interior Designer</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Handyman</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Professional Painters</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Real Estate Instructors & Influencers</a>
                                </li>
                            </ul>
                        </li>
                        
                        <li className="relative block border-b-primary border-b">
                            <span className="menu-expand"></span>
                            <a href="" className="block capitalize font-normal text-black hover:text-secondary text-base my-2 py-1 px-5">Investor</a>
                            <ul className="offcanvas-submenu static top-auto hidden w-full visible opacity-100 capitalize" style={{display: 'none'}}>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Sell to an Investor</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Buy from an Investor</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">Off Market Properties</a>
                                </li>
                                <li>
                                    <a className="text-sm py-2 px-[30px] text-black font-light block transition-all hover:text-secondary" href="#">All Investor Properties</a>
                                </li>
                            </ul>
                        </li>
                        
                        <li className="relative block border-b-primary border-b">
                            <a href="" className="relative block capitalize font-normal text-black hover:text-secondary text-base my-2 py-1 px-5">Blogs</a>
                        </li>
                        
                        <li className="relative block border-b-primary border-b">
                            <a href="" className="relative block capitalize text-black hover:text-secondary text-base my-2 py-1 px-5">Contact</a>
                        </li>
                    </ul>
                </nav>
                
                {/* <!-- offcanvas-menu end --> */}

                <div className="px-5 flex flex-wrap mt-3 sm:hidden">
                    <a href="#" className="before:rounded-md before:block before:absolute before:left-auto before:right-0 before:inset-y-0 before:-z-[1] before:bg-secondary before:w-0 hover:before:w-full hover:before:left-0 hover:before:right-auto before:transition-all leading-none px-[20px] py-[15px] capitalize font-medium text-white text-[14px] xl:text-[16px] relative after:block after:absolute after:inset-0 after:-z-[2] after:bg-primary after:rounded-md after:transition-all">Add Property</a>
                </div>



            </div>
        </div>
        {/* <!-- offcanvas-mobile-menu end --> */}
		</>	
	);
}

export default OffCanvasMenu;