import React from "react";

import HeaderTop from "../HeaderTop";
import BreadCrumb from "../BreadCrumb";
import VideoSection from "../VideoSection";
import NewsLetter from "../NewsLetter";
import RegisterForm from "./RegisterForm";

import BreadCrumbs from "../data/breadcrumbs.json";

function Register(){
	const BreadCrumbData = BreadCrumbs.register
	return(
		<>
			<HeaderTop />
			<BreadCrumb {...BreadCrumbData}/>
			<RegisterForm />
			<VideoSection />
			<NewsLetter />
		</>
	)
}

export default Register;