import React from "react"
import PropertiesCard from '../PropertyCard'

import Property from '../data/properties.json'


function FeaturedProperties(){
	const property = Property
	return(
		<section className="featured-properties py-[80px] lg:py-[125px]">
			<div className="container">
				<div className="grid grid-cols-12">
					<div className="col-span-12">
						<span className="text-secondary text-tiny inline-block mb-2">Properties</span>
					</div>
					<div className="col-span-12 flex flex-wrap flex-col md:flex-row items-start justify-between mb-[50px]">
						<div className="mb-5 lg:mb-0">

							<h2 className="font-lora text-primary text-[24px] sm:text-[30px] xl:text-xl capitalize font-medium">
							To buy on Oren Access<span className="text-secondary">.</span></h2>
						</div>
						<ul className="all-properties flex flex-wrap lg:pt-[10px]">
							<li data-tab="all-properties" className="mr-[30px] md:mr-[45px] mb-4 lg:mb-0 leading-none active">
								<button className="leading-none capitalize text-primary hover:text-secondary transition-all text-[16px] ease-out">All Properties</button>
								</li>
							<li data-tab="Buy" className="mr-[30px] md:mr-[45px] mb-4 lg:mb-0 leading-none">
								<button className="leading-none capitalize text-primary hover:text-secondary transition-all text-[16px] ease-out">Buy</button>
							</li>
							<li data-tab="Sale" className="mr-[30px] md:mr-[45px] mb-4 lg:mb-0 leading-none">
								<button className="leading-none capitalize text-primary hover:text-secondary transition-all text-[16px] ease-out">Sale</button>
							</li>
							<li data-tab="Rental" className="mr-[30px] md:mr-[45px] mb-4 lg:mb-0 leading-none">
								<button className="leading-none capitalize text-primary hover:text-secondary transition-all text-[16px] ease-out">Rental</button>
							</li>
							<li data-tab="Professional" className="mr-[30px] md:mr-[45px] mb-4 lg:mb-0 leading-none">
								<button className="leading-none capitalize text-primary hover:text-secondary transition-all text-[16px] ease-out">Professional</button>
							</li>
							<li data-tab="Commercial" className="mr-[30px] md:mr-[45px] mb-4 lg:mb-0 leading-none">
								<button className="leading-none capitalize text-primary hover:text-secondary transition-all text-[16px] ease-out">Investor</button>
							</li>
						</ul>
					</div>
					<div className="col-span-12">
						<div className="all-properties properties-tab-content active">
							<div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-[30px]">

								{property.properties.map((item, index) => (
									<PropertiesCard key={index} data={item} />
								))}
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
				
	)
}

export default FeaturedProperties;