import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import MemberCard from "./MemberCard"
import TeamLists from "../../data/teamList.json"

import Heart from "../../../images/icon/heart.svg"
import Media from "../../../images/icon/media.svg"
import Camera from "../../../images/icon/camera.png"
import Video from "../../../images/icon/video.png"

function TeamSection(){

	return(
		<section className="popular-properties py-[80px] lg:py-[120px]">
			<div className="container">
				<div className="grid grid-cols-12 mb-[-30px] gap-[30px] xl:gap-[50px]">
					<div className="col-span-12 md:col-span-6 lg:col-span-8 mb-[30px]">
						<div className="grid grid-cols-12 mb-[30px] gap-[30px] items-center">
							<div className="col-span-4 lg:col-span-6">
								
							</div>
							<div className="col-span-8 lg:col-span-6 text-right">
								<span className="text-primary">Sort by:</span>
								<div className="selectric-wrapper selectric-bg-white selectric-text-[#9C9C9C] selectric-text[14px] selectric-capitalize selectric-cursor-pointer selectric-nice-select selectric-sorting-select">
									<div className="selectric-hide-select">
										<select name="select" id="select" className="bg-white text-[#9C9C9C] text[14px] capitalize cursor-pointer nice-select sorting-select" tabindex="-1">
											<option value="0" selected="">Default Order</option>
											<option value="1">A to Z</option>
											<option value="2">Z to A</option>
											<option value="3">All</option>
										</select>
									</div>
									<div className="selectric">
										<span className="label">Default Order</span>
										<b className="button">▾</b>
									</div>
									<div className="selectric-items" tabindex="-1">
										<div className="selectric-scroll">
											<ul>
												<li data-index="0" className="selected">Default Order</li>
												<li data-index="1" className="">A to Z</li>
												<li data-index="2" className="">Z to A</li>
												<li data-index="3" className="last">All</li>
											</ul>
										</div>
									</div>
									<input className="selectric-input" tabindex="0" />
								</div>
							</div>
						</div>

						<div id="list" className="list grid-tab-content active">
							<div className="col-span-12">
								<div className="grid grid-cols-1 gap-[30px]">
									{TeamLists.map((member, index) => (
                                        <MemberCard key={index} data={member} />
                                    ))}
								</div>
							</div>
						</div>

						<div className="grid grid-cols-12 mt-[50px] gap-[30px]">
							<div className="col-span-12">
								<ul className="pagination flex flex-wrap items-center justify-center">
									<li className="mx-2">
										<a className="flex flex-wrap items-center justify-center w-[26px] h-[26px] bg-primary rounded-full text-orange leading-none transition-all hover:bg-secondary text-white text-[12px]" href="#">
											<svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
												<g clip-path="url(.clip0_876_580)">
													<path d="M5.8853 10.0592C5.7326 10.212 5.48474 10.212 5.33204 10.0592L0.636322 5.36134C0.48362 5.20856 0.48362 4.96059 0.636322 4.80782L5.33204 0.109909C5.48749 -0.0403413 5.73535 -0.0359829 5.8853 0.119544C6.03181 0.271171 6.03181 0.511801 5.8853 0.663428L1.46633 5.08446L5.8853 9.50573C6.03823 9.65873 6.03823 9.90648 5.8853 10.0592Z" fill="white"></path>
												</g>
												<defs>
													<clipPath className="clip0_876_580">
														<rect width="5.47826" height="10.1739" fill="white" transform="matrix(-1 0 0 1 6 0)"></rect>
													</clipPath>
												</defs>
											</svg>
										</a>
									</li>

									<li className="mx-2">
										<a className="flex flex-wrap items-center justify-center w-[26px] h-[26px] leading-none hover:text-secondary" href="#">1</a>
									</li>

									<li className="mx-2">
										<a className="flex flex-wrap items-center justify-center w-[26px] h-[26px] leading-none hover:text-secondary" href="#">2</a>
									</li>

									<li className="mx-2">
										<a className="flex flex-wrap items-center justify-center w-[26px] h-[26px] leading-none hover:text-secondary" href="#">3</a>
									</li>

									<li className="mx-2">
										<a className="flex flex-wrap items-center justify-center w-[26px] h-[26px] leading-none hover:text-secondary" href="#">4</a>
									</li>

									<li className="mx-2">
										<span>---</span>
									</li>

									<li className="mx-2">
										<a className="flex flex-wrap items-center justify-center w-[26px] h-[26px] leading-none hover:text-secondary" href="#">25</a>
									</li>

									<li className="mx-2">
										<a className="flex flex-wrap items-center justify-center w-[26px] h-[26px] bg-primary rounded-full text-orange leading-none transition-all hover:bg-secondary text-white text-[12px]" href="#">
											<svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
												<g clip-path="url(.clip0_876_576)">
													<path d="M0.114699 10.0592C0.267401 10.212 0.515257 10.212 0.667959 10.0592L5.36368 5.36134C5.51638 5.20856 5.51638 4.96059 5.36368 4.80782L0.667959 0.109909C0.512505 -0.0403413 0.26465 -0.0359829 0.114699 0.119544C-0.031813 0.271171 -0.031813 0.511801 0.114699 0.663428L4.53367 5.08446L0.114699 9.50573C-0.038233 9.65873 -0.038233 9.90648 0.114699 10.0592Z" fill="white"></path>
												</g>
												<defs>
													<clipPath className="clip0_876_576">
														<rect width="5.47826" height="10.1739" fill="white"></rect>
													</clipPath>
												</defs>
											</svg>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>

					<div className="col-span-12 md:col-span-6 lg:col-span-4 md:order-first mb-[30px]">
						<aside className="mb-[-60px] asidebar">
							
							<div className="mb-[60px]">
								<h3 className="text-primary leading-none text-[24px] font-lora underline mb-[40px] font-medium">Featured Property<span className="text-secondary">.</span></h3>
								<div  className="sidebar-carousel relative">
									<Swiper spaceBetween={40} slidesPerView={1} pagination={{ clickable: true }} className="swiper p-1">

											<SwiperSlide className="swiper-slide">
												<div className="overflow-hidden rounded-md drop-shadow-[0px_2px_3px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center mb-[40px]">
													<div className="relative">
														<a href="" className="block">
															<img src="/images/properties/properties4.jpg" className="w-full h-full" loading="lazy" width="370" height="266" alt="" />
														</a>
														<div className="flex flex-wrap flex-col absolute top-5 right-5">
															<button className="flex flex-wrap items-center bg-primary p-[5px] rounded-[2px] text-white mb-[5px] text-xs">
																<img className="mr-1" src={Camera} loading="lazy" width="13" height="10" alt="camera icon" />
																07
															</button>
															<button className="flex flex-wrap items-center bg-primary p-[5px] rounded-[2px] text-white text-xs">
																<img className="mr-1" src={Video} loading="lazy" width="14" height="10" alt="camera icon" />
																08
															</button>
														</div>
													</div>

													<div className="pt-[15px] pb-[20px] px-[20px] text-left">
														<h3><a href="" className="font-lora leading-tight text-[18px] text-primary">Orchid Casel de Paradise.</a></h3>
														<h4 className="leading-none"><a href="" className="font-light text-[14px] leading-[1.75] text-primary underline">18B Central Street, San Francisco</a></h4>
														<ul className="mt-[10px]">
															<li className="flex flex-wrap items-center justify-between">
																<span className="font-lora text-[14px] text-secondary leading-none">Price: $255300</span>

																<span className="flex flex-wrap items-center">
																	<button className="mr-[15px] text-[#B1AEAE] hover:text-secondary">
																		<img src={Media} alt="" />
																	</button>
																	<button className="text-[#B1AEAE] hover:text-secondary">
																		<img src={Heart} alt="" />
																	</button>
																</span>
															</li>
														</ul>
													</div>
												</div>
												<div className="overflow-hidden rounded-md drop-shadow-[0px_2px_3px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center">
													<div className="relative">
														<a href="" className="block">
															<img src="/images/properties/properties5.jpg" className="w-full h-full" loading="lazy" width="370" height="266" alt="This" />
														</a>
														<div className="flex flex-wrap flex-col absolute top-5 right-5">
															<button className="flex flex-wrap items-center bg-primary p-[5px] rounded-[2px] text-white mb-[5px] text-xs">
																<img className="mr-1" src={Camera} loading="lazy" width="13" height="10" alt="camera icon" />
																07
															</button>
															<button className="flex flex-wrap items-center bg-primary p-[5px] rounded-[2px] text-white text-xs">
																<img className="mr-1" src={Video} loading="lazy" width="14" height="10" alt="camera icon" />
																08
															</button>
														</div>
													</div>

													<div className="pt-[15px] pb-[20px] px-[20px] text-left">
														<h3><a href="" className="font-lora leading-tight text-[18px] text-primary">Sindrela Resort Villa.</a></h3>
														<h4 className="leading-none"><a href="" className="font-light text-[14px] leading-[1.75] text-primary underline">18B Central Street, San Francisco</a></h4>
														<ul className="mt-[10px]">
															<li className="flex flex-wrap items-center justify-between">
																<span className="font-lora text-[14px] text-secondary leading-none">Price:$185300</span>

																<span className="flex flex-wrap items-center">
																	<button className="mr-[15px] text-[#B1AEAE] hover:text-secondary">
																		<img src={Media} alt="" />
																	</button>
																	<button className="text-[#B1AEAE] hover:text-secondary">
																		<img src={Heart} alt="" />
																	</button>
																</span>
															</li>
														</ul>
													</div>
												</div>
											</SwiperSlide>
											<SwiperSlide className="swiper-slide">
												<div className="overflow-hidden rounded-md drop-shadow-[0px_2px_3px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center mb-[40px]">
													<div className="relative">
														<a href="" className="block">
															<img src="/images/properties/properties1.jpg" className="w-full h-full" loading="lazy" width="370" height="266" alt="This" />
														</a>
														<div className="flex flex-wrap flex-col absolute top-5 right-5">
															<button className="flex flex-wrap items-center bg-primary p-[5px] rounded-[2px] text-white mb-[5px] text-xs">
																<img className="mr-1" src={Camera} loading="lazy" width="13" height="10" alt="camera icon" />
																07
															</button>
															<button className="flex flex-wrap items-center bg-primary p-[5px] rounded-[2px] text-white text-xs">
																<img className="mr-1" src={Video} loading="lazy" width="14" height="10" alt="camera icon" />
																08
															</button>
														</div>
													</div>

													<div className="pt-[15px] pb-[20px] px-[20px] text-left">
														<h3><a href="" className="font-lora leading-tight text-[18px] text-primary">Sindrela Resort Villa.</a></h3>
														<h4 className="leading-none"><a href="" className="font-light text-[14px] leading-[1.75] text-primary underline">18B Central Street, San Francisco</a></h4>
														<ul className="mt-[10px]">
															<li className="flex flex-wrap items-center justify-between">
																<span className="font-lora text-[14px] text-secondary leading-none">Price:$185300</span>

																<span className="flex flex-wrap items-center">
																	<button className="mr-[15px] text-[#B1AEAE] hover:text-secondary">
																		<img src={Media} alt="" />
																	</button>
																	<button className="text-[#B1AEAE] hover:text-secondary">
																		<img src={Heart} alt="" />
																	</button>
																</span>
															</li>
														</ul>
													</div>
												</div>
												<div className="overflow-hidden rounded-md drop-shadow-[0px_2px_3px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center">
													<div className="relative">
														<a href="" className="block">
															<img src="/images/properties/properties8.jpg" className="w-full h-full" loading="lazy" width="370" height="266" alt="This" />
														</a>
														<div className="flex flex-wrap flex-col absolute top-5 right-5">
															<button className="flex flex-wrap items-center bg-primary p-[5px] rounded-[2px] text-white mb-[5px] text-xs">
																<img className="mr-1" src={Camera} loading="lazy" width="13" height="10" alt="camera icon" />
																07
															</button>
															<button className="flex flex-wrap items-center bg-primary p-[5px] rounded-[2px] text-white text-xs">
																<img className="mr-1" src={Video} loading="lazy" width="14" height="10" alt="camera icon" />
																08
															</button>
														</div>
													</div>

													<div className="pt-[15px] pb-[20px] px-[20px] text-left">
														<h3><a href="" className="font-lora leading-tight text-[18px] text-primary">Sindrela Resort Villa.</a></h3>
														<h4 className="leading-none"><a href="" className="font-light text-[14px] leading-[1.75] text-primary underline">18B Central Street, San Francisco</a></h4>
														<ul className="mt-[10px]">
															<li className="flex flex-wrap items-center justify-between">
																<span className="font-lora text-[14px] text-secondary leading-none">Price:$185300</span>

																<span className="flex flex-wrap items-center">
																	<button className="mr-[15px] text-[#B1AEAE] hover:text-secondary">
																		<img src={Media} alt="" />
																	</button>
																	<button className="text-[#B1AEAE] hover:text-secondary">
																		<img src={Heart} alt="" />
																	</button>
																</span>
															</li>
														</ul>
													</div>
												</div>
											</SwiperSlide>	
											
									</Swiper>
									{/* <!-- If we need navigation buttons --> */}
									<div className="flex flex-wrap items-center justify-center mt-[25px]">
										<div className="swiper-button-prev w-[26px] h-[26px] rounded-full bg-primary text-white hover:bg-secondary static mx-[5px] mt-[0px]"></div>
										<div className="swiper-button-next w-[26px] h-[26px] rounded-full bg-primary text-white hover:bg-secondary static mx-[5px] mt-[0px]"></div>
									</div>
								</div>
							</div>

							

							<div className="mb-[60px]">
								<h3 className="text-primary leading-none text-[24px] font-lora underline mb-[40px] font-medium">
									Tags
									<span className="text-secondary">.</span>
								</h3>
								<ul className="flex flex-wrap my-[-7px] mx-[-5px] font-light text-[12px]">
									<li className="my-[7px] mx-[5px]"><a href="#" className="leading-none border border-[#E0E0E0] py-[8px] px-[10px] block rounded-[4px] hover:text-secondary">Real Estate</a></li>
									<li className="my-[7px] mx-[5px]"><a href="#" className="leading-none border border-[#E0E0E0] py-[8px] px-[10px] block rounded-[4px] hover:text-secondary">Appartment</a></li>
									<li className="my-[7px] mx-[5px]"><a href="#" className="leading-none border border-[#E0E0E0] py-[8px] px-[10px] block rounded-[4px] hover:text-secondary">Sale Property</a></li>
									<li className="my-[7px] mx-[5px]"><a href="#" className="leading-none border border-[#E0E0E0] py-[8px] px-[10px] block rounded-[4px] hover:text-secondary">Duplex</a></li>
									<li className="my-[7px] mx-[5px]"><a href="#" className="leading-none border border-[#E0E0E0] py-[8px] px-[10px] block rounded-[4px] hover:text-secondary">Buy Property</a></li>
									<li className="my-[7px] mx-[5px]"><a href="#" className="leading-none border border-[#E0E0E0] py-[8px] px-[10px] block rounded-[4px] hover:text-secondary">Houses</a></li>
								</ul>
							</div>
						</aside>
					</div>
				</div>
			</div>
		</section>

	)
}

export default TeamSection