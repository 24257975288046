import React from "react";

import Stickers from '../data/brandSticker.json'
import BrandSticker from "./BrandSticker";

function BrandSection(){
    const StickerData = Stickers

	return(
		<section className="brand-section pt-[80px] lg:pt-[125px] pb-[80px] lg:pb-[125px]">
            <div className="container">
                <div className="grid grid-cols-12">
                    <div className="col-span-12">
                        <div className="mb-[60px] text-center">
                            <span className="text-secondary text-tiny inline-block mb-2">It’s just about everyone in the real estate service.</span>
                            <h2 className="font-lora text-primary text-[24px] sm:text-[30px] xl:text-xl capitalize font-medium">
                                Companies That Can Utilize Our Highly Effective Services
                                <span className="text-secondary">.</span>
                            </h2>
                        </div>
                    </div>
                    <div className="col-span-12 flex flex-wrap justify-around gap-y-8 gap-x-[8rem]">

                            {StickerData.brandSticker.map((item, index) => (
                                <BrandSticker key={index} info={item} />
                            ))}
                        
                    </div>

                </div>
            </div>
        </section>
	)
}

export default BrandSection;