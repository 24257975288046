import React from 'react';
import { Outlet } from 'react-router-dom';

import Header from './components/Header';
import OffCanvasMenu from './components/OffCanvasMenu';


import Footer from './components/Footer';

function Layout(){
	return(
		<>
			<Header />
			<OffCanvasMenu />
			<Outlet />
			<Footer />
		</>
	)
}

export default Layout;