import {React, useState} from "react";

function TopFilter(){
	const [moreFilter, setMoreFilter] = useState(false)
    const handleClick = (e) =>{
        e.preventDefault()
        setMoreFilter(!moreFilter)
    }
	return(
		<div className="col-span-12 top-filter ">
			<form action="" className="relative grid grid-cols-12 gap-x-4">
				<div className="relative mb-[25px] bg-white col-span-12 md:col-span-6 lg:col-span-4 ">
					<input className="font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white" type="text" placeholder="Location" />
					<svg className="absolute top-1/2 -translate-y-1/2 z-[1] left-[20px] pointer-events-none" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M5.39648 6.41666H8.60482" stroke="#016450" strokeWidth="1.5" strokeLinecap="round" />
						<path d="M7 8.02083V4.8125" stroke="#016450" strokeWidth="1.5" strokeLinecap="round" />
						<path d="M2.11231 4.9525C3.26148 -0.0991679 10.7456 -0.0933345 11.889 4.95833C12.5598 7.92167 10.7165 10.43 9.10064 11.9817C7.92814 13.1133 6.07314 13.1133 4.89481 11.9817C3.28481 10.43 1.44148 7.91583 2.11231 4.9525Z" stroke="#0B2C3D" strokeWidth="1.5" />
					</svg>
				</div>
				<div className="relative mb-[25px] bg-white col-span-6 md:col-span-6 lg:col-span-2 ">
					<svg className="absolute top-1/2 -translate-y-1/2 z-[1] left-[20px] pointer-events-none" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.33268 4.66667H4.66602V9.33334H9.33268V4.66667Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M2.91602 12.8333C3.87852 12.8333 4.66602 12.0458 4.66602 11.0833V9.33333H2.91602C1.95352 9.33333 1.16602 10.1208 1.16602 11.0833C1.16602 12.0458 1.95352 12.8333 2.91602 12.8333Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M2.91602 4.66667H4.66602V2.91667C4.66602 1.95417 3.87852 1.16667 2.91602 1.16667C1.95352 1.16667 1.16602 1.95417 1.16602 2.91667C1.16602 3.87917 1.95352 4.66667 2.91602 4.66667Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M9.33398 4.66667H11.084C12.0465 4.66667 12.834 3.87917 12.834 2.91667C12.834 1.95417 12.0465 1.16667 11.084 1.16667C10.1215 1.16667 9.33398 1.95417 9.33398 2.91667V4.66667Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M11.084 12.8333C12.0465 12.8333 12.834 12.0458 12.834 11.0833C12.834 10.1208 12.0465 9.33333 11.084 9.33333H9.33398V11.0833C9.33398 12.0458 10.1215 12.8333 11.084 12.8333Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
					</svg>
					<select className="nice-select font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white appearance-none cursor-pointer">
						<option defaultValue="0">For Sale</option>
						<option value="1">For Sale</option>
						<option value="2">For Rent</option>
						<option value="3">Sold</option>
					</select>
				</div>
				<div className="relative mb-[25px] bg-white col-span-6 md:col-span-6 lg:col-span-2">
					<svg className="absolute top-1/2 -translate-y-1/2 z-[1] left-[20px] pointer-events-none" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clipPath="url(#clip0_928_754)">
							<path d="M4.64311 0H0V4.64311H4.64311V0ZM3.71437 3.71437H0.928741V0.928741H3.71437V3.71437Z" fill="#0B2C3D" />
							<path d="M8.35742 0V4.64311H13.0005V0H8.35742ZM12.0718 3.71437H9.28616V0.928741H12.0718V3.71437Z" fill="#0B2C3D" />
							<path d="M0 13H4.64311V8.35689H0V13ZM0.928741 9.28563H3.71437V12.0713H0.928741V9.28563Z" fill="#0B2C3D" />
							<path d="M8.35742 13H13.0005V8.35689H8.35742V13ZM9.28616 9.28563H12.0718V12.0713H9.28616V9.28563Z" fill="#0B2C3D" />
							<path d="M6.96437 0H6.03563V6.03563H0V6.96437H6.03563V13H6.96437V6.96437H13V6.03563H6.96437V0Z" fill="#0B2C3D" />
						</g>
						<defs>
							<clipPath id="clip0_928_754">
								<rect width="13" height="13" fill="white" />
							</clipPath>
						</defs>
					</svg>
					
					<select className="nice-select font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white appearance-none cursor-pointer">
						<option value="0">Bedrooms</option>
						<option value="5" >Any</option>
						<option value="1">1+</option>
						<option value="2">2+</option>
						<option value="3">3+</option>
						<option value="4">4+</option>
					</select>
				</div>
				
				<div className="relative mb-[25px] bg-white col-span-6 md:col-span-6 lg:col-span-2 ">
					<svg className="absolute top-1/2 -translate-y-1/2 z-[1] left-[20px] pointer-events-none" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.33268 4.66667H4.66602V9.33334H9.33268V4.66667Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M2.91602 12.8333C3.87852 12.8333 4.66602 12.0458 4.66602 11.0833V9.33333H2.91602C1.95352 9.33333 1.16602 10.1208 1.16602 11.0833C1.16602 12.0458 1.95352 12.8333 2.91602 12.8333Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M2.91602 4.66667H4.66602V2.91667C4.66602 1.95417 3.87852 1.16667 2.91602 1.16667C1.95352 1.16667 1.16602 1.95417 1.16602 2.91667C1.16602 3.87917 1.95352 4.66667 2.91602 4.66667Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M9.33398 4.66667H11.084C12.0465 4.66667 12.834 3.87917 12.834 2.91667C12.834 1.95417 12.0465 1.16667 11.084 1.16667C10.1215 1.16667 9.33398 1.95417 9.33398 2.91667V4.66667Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M11.084 12.8333C12.0465 12.8333 12.834 12.0458 12.834 11.0833C12.834 10.1208 12.0465 9.33333 11.084 9.33333H9.33398V11.0833C9.33398 12.0458 10.1215 12.8333 11.084 12.8333Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
					</svg>
					<button className="w-full border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white appearance-none cursor-pointer" onClick={handleClick}>More</button>

					<div class={` ${moreFilter ? '' : 'hidden'} w-96 my-2 rounded-md absolute z-50 bg-white shadow-lg grid grid-cols-12 px-4 gap-2 border-secondary border h-[30rem] overflow-auto`}>
						<h4 className="col-span-12 text-center py-2 text-base font-bold">More Filters</h4>
						<div className="relative mb-[25px] bg-white col-span-6 ">
							<svg className="absolute top-1/2 -translate-y-1/2 z-[1] left-[20px] pointer-events-none" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g clipPath="url(#clip0_928_754)">
									<path d="M4.64311 0H0V4.64311H4.64311V0ZM3.71437 3.71437H0.928741V0.928741H3.71437V3.71437Z" fill="#0B2C3D" />
									<path d="M8.35742 0V4.64311H13.0005V0H8.35742ZM12.0718 3.71437H9.28616V0.928741H12.0718V3.71437Z" fill="#0B2C3D" />
									<path d="M0 13H4.64311V8.35689H0V13ZM0.928741 9.28563H3.71437V12.0713H0.928741V9.28563Z" fill="#0B2C3D" />
									<path d="M8.35742 13H13.0005V8.35689H8.35742V13ZM9.28616 9.28563H12.0718V12.0713H9.28616V9.28563Z" fill="#0B2C3D" />
									<path d="M6.96437 0H6.03563V6.03563H0V6.96437H6.03563V13H6.96437V6.96437H13V6.03563H6.96437V0Z" fill="#0B2C3D" />
								</g>
								<defs>
									<clipPath id="clip0_928_754">
										<rect width="13" height="13" fill="white" />
									</clipPath>
								</defs>
							</svg>
							<select className="nice-select font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white appearance-none cursor-pointer">
								<option value="0">Bathrooms</option>
								<option value="5" >Any</option>
								<option value="1">1+</option>
								<option value="2">2+</option>
								<option value="3">3+</option>
								<option value="4">4+</option>
							</select>
						</div>
						<div className="relative mb-[25px] bg-white col-span-6 m">
							<svg className="absolute top-1/2 -translate-y-1/2 z-[1] left-[20px] pointer-events-none" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9.33268 4.66667H4.66602V9.33334H9.33268V4.66667Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M2.91602 12.8333C3.87852 12.8333 4.66602 12.0458 4.66602 11.0833V9.33333H2.91602C1.95352 9.33333 1.16602 10.1208 1.16602 11.0833C1.16602 12.0458 1.95352 12.8333 2.91602 12.8333Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M2.91602 4.66667H4.66602V2.91667C4.66602 1.95417 3.87852 1.16667 2.91602 1.16667C1.95352 1.16667 1.16602 1.95417 1.16602 2.91667C1.16602 3.87917 1.95352 4.66667 2.91602 4.66667Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M9.33398 4.66667H11.084C12.0465 4.66667 12.834 3.87917 12.834 2.91667C12.834 1.95417 12.0465 1.16667 11.084 1.16667C10.1215 1.16667 9.33398 1.95417 9.33398 2.91667V4.66667Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M11.084 12.8333C12.0465 12.8333 12.834 12.0458 12.834 11.0833C12.834 10.1208 12.0465 9.33333 11.084 9.33333H9.33398V11.0833C9.33398 12.0458 10.1215 12.8333 11.084 12.8333Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
							<select className="nice-select font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white appearance-none cursor-pointer">
								<option defaultValue="0">Parking Spots</option>
								<option value="1">Must Have</option>
								<option value="2">2+</option>
								<option value="3">3+</option>
								<option value="4">Any</option>
							</select>
						</div>
						<div className="relative mb-[25px] bg-white col-span-6 ">
							<svg className="absolute top-1/2 -translate-y-1/2 z-[1] left-[20px] pointer-events-none" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g clipPath="url(#clip0_928_754)">
									<path d="M4.64311 0H0V4.64311H4.64311V0ZM3.71437 3.71437H0.928741V0.928741H3.71437V3.71437Z" fill="#0B2C3D" />
									<path d="M8.35742 0V4.64311H13.0005V0H8.35742ZM12.0718 3.71437H9.28616V0.928741H12.0718V3.71437Z" fill="#0B2C3D" />
									<path d="M0 13H4.64311V8.35689H0V13ZM0.928741 9.28563H3.71437V12.0713H0.928741V9.28563Z" fill="#0B2C3D" />
									<path d="M8.35742 13H13.0005V8.35689H8.35742V13ZM9.28616 9.28563H12.0718V12.0713H9.28616V9.28563Z" fill="#0B2C3D" />
									<path d="M6.96437 0H6.03563V6.03563H0V6.96437H6.03563V13H6.96437V6.96437H13V6.03563H6.96437V0Z" fill="#0B2C3D" />
								</g>
								<defs>
									<clipPath id="clip0_928_754">
										<rect width="13" height="13" fill="white" />
									</clipPath>
								</defs>
							</svg>
							<select className="nice-select font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white appearance-none cursor-pointer">
								<option value="0">Home Type</option>
								<option value="1">Houses</option>
								<option value="2">Townhouse</option>
								<option value="3">Multi Family</option>
								<option value="4">Condos/Co-ops</option>
								<option value="5" >Lost/Land</option>
								<option value="6">Apartments</option>
								<option value="7">Manufactured</option>
							</select>
						</div>
						<div className="relative mb-[25px] bg-white col-span-6 ">
							<svg className="absolute top-1/2 -translate-y-1/2 z-[1] left-[20px] pointer-events-none" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g clipPath="url(#clip0_928_754)">
									<path d="M4.64311 0H0V4.64311H4.64311V0ZM3.71437 3.71437H0.928741V0.928741H3.71437V3.71437Z" fill="#0B2C3D" />
									<path d="M8.35742 0V4.64311H13.0005V0H8.35742ZM12.0718 3.71437H9.28616V0.928741H12.0718V3.71437Z" fill="#0B2C3D" />
									<path d="M0 13H4.64311V8.35689H0V13ZM0.928741 9.28563H3.71437V12.0713H0.928741V9.28563Z" fill="#0B2C3D" />
									<path d="M8.35742 13H13.0005V8.35689H8.35742V13ZM9.28616 9.28563H12.0718V12.0713H9.28616V9.28563Z" fill="#0B2C3D" />
									<path d="M6.96437 0H6.03563V6.03563H0V6.96437H6.03563V13H6.96437V6.96437H13V6.03563H6.96437V0Z" fill="#0B2C3D" />
								</g>
								<defs>
									<clipPath id="clip0_928_754">
										<rect width="13" height="13" fill="white" />
									</clipPath>
								</defs>
							</svg>
							
							<select className="nice-select font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white appearance-none cursor-pointer">
								<option value="0">Max HOA</option>
								<option value="1" >Any</option>
								<option value="2">No HOA Fee</option>
								<option value="3">$50/month</option>
								<option value="4">$100/month</option>
								<option value="5">$200/month</option>
								<option value="6">$300/month</option>
								<option value="7">$400/month</option>
								<option value="8">$500/month</option>
								<option value="9">$600/month</option>
								<option value="10">$700/month</option>
								<option value="11">$800/month</option>
								<option value="12">$900/month</option>
								<option value="13">$1000/month</option>
							</select>
						</div>
						<div className="relative mb-[25px] bg-white col-span-6 ">
							<svg className="absolute top-1/2 -translate-y-1/2 z-[1] left-[20px] pointer-events-none" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g clipPath="url(#clip0_928_754)">
									<path d="M4.64311 0H0V4.64311H4.64311V0ZM3.71437 3.71437H0.928741V0.928741H3.71437V3.71437Z" fill="#0B2C3D" />
									<path d="M8.35742 0V4.64311H13.0005V0H8.35742ZM12.0718 3.71437H9.28616V0.928741H12.0718V3.71437Z" fill="#0B2C3D" />
									<path d="M0 13H4.64311V8.35689H0V13ZM0.928741 9.28563H3.71437V12.0713H0.928741V9.28563Z" fill="#0B2C3D" />
									<path d="M8.35742 13H13.0005V8.35689H8.35742V13ZM9.28616 9.28563H12.0718V12.0713H9.28616V9.28563Z" fill="#0B2C3D" />
									<path d="M6.96437 0H6.03563V6.03563H0V6.96437H6.03563V13H6.96437V6.96437H13V6.03563H6.96437V0Z" fill="#0B2C3D" />
								</g>
								<defs>
									<clipPath id="clip0_928_754">
										<rect width="13" height="13" fill="white" />
									</clipPath>
								</defs>
							</svg>
							<select className="nice-select font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white appearance-none cursor-pointer">
								<option value="0">Days On Oren Access</option>
								<option value="1" >1 Days</option>
								<option value="2">7 Days</option>
								<option value="3">14 Days</option>
								<option value="4">30 Days</option>
								<option value="5">90 Days</option>
								<option value="6">6 Months</option>
								<option value="7">1 Year</option>
								<option value="8">2 Years</option>
								<option value="9">3 Years</option>
							</select>
						</div>
						<div className="relative mb-[25px] bg-white col-span-6  ">
							<svg className="absolute top-1/2 -translate-y-1/2 z-[1] left-[20px] pointer-events-none" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9.33268 4.66667H4.66602V9.33334H9.33268V4.66667Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M2.91602 12.8333C3.87852 12.8333 4.66602 12.0458 4.66602 11.0833V9.33333H2.91602C1.95352 9.33333 1.16602 10.1208 1.16602 11.0833C1.16602 12.0458 1.95352 12.8333 2.91602 12.8333Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M2.91602 4.66667H4.66602V2.91667C4.66602 1.95417 3.87852 1.16667 2.91602 1.16667C1.95352 1.16667 1.16602 1.95417 1.16602 2.91667C1.16602 3.87917 1.95352 4.66667 2.91602 4.66667Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M9.33398 4.66667H11.084C12.0465 4.66667 12.834 3.87917 12.834 2.91667C12.834 1.95417 12.0465 1.16667 11.084 1.16667C10.1215 1.16667 9.33398 1.95417 9.33398 2.91667V4.66667Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M11.084 12.8333C12.0465 12.8333 12.834 12.0458 12.834 11.0833C12.834 10.1208 12.0465 9.33333 11.084 9.33333H9.33398V11.0833C9.33398 12.0458 10.1215 12.8333 11.084 12.8333Z" stroke="#0B2C3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
							<select className="nice-select font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white appearance-none cursor-pointer">
								<option defaultValue="0">Parking Spots</option>
								<option value="1">Must Have</option>
								<option value="2">2+</option>
								<option value="3">3+</option>
								<option value="4">Any</option>
							</select>
						</div>
						<div class="relative mb-[25px] bg-white col-span-12  ">
							<h5 className="font-bold text-base text-black">Property Status</h5>
							<input type="checkbox" id="propertyStatus1" name="coming soon"/>
							<label htmlFor="propertyStatus1" className="ml-[5px] cursor-pointer text-[14px] font-semibold"> Coming Soon</label>
							<details className="inline ml-4 border-b text-secondary">
								<summary></summary>
								<p className="inline text-sm font-lora leading-[0] text-slate-500">Coming Soon listings are homes that will soon be on the market. The listing agent for these homes has added a Coming Soon note to alert buyers in advance.</p>
							</details>
							<br/>
							<input type="checkbox" id="propertyStatus2" name="accepting backup offers"/>
							<label for="propertyStatus2" className="ml-[5px] cursor-pointer text-[14px] font-semibold">Accepting backup offers</label><br/>
							<input type="checkbox" id="propertyStatus3" name="Pending & Under Contract"/>
							<label htmlFor="propertyStatus3" className="ml-[5px] cursor-pointer text-[14px] font-semibold">Pending & Under Contract</label>
							<details className="inline ml-4 border-b text-secondary">
								<summary></summary>
								<p className="inline text-sm font-lora leading-[0] text-slate-500">Sellers of these homes have accepted a buyer's offer; however, the home has not closed.</p>
							</details>
							<br/>
						</div>
						<div class="relative mb-[25px] bg-white col-span-12  ">
							<h5 className="font-bold text-base text-black">Tours</h5>
							<input type="checkbox" name="Must Have Open House" id="tours1" />
							<label className="ml-[5px] cursor-pointer text-[14px] font-semibold" htmlFor="tours1">Must Have Open House</label> <br />
							<input type="checkbox" name="Must Have 3D Tour" id="tours2" />
							<label className="ml-[5px] cursor-pointer text-[14px] font-semibold" htmlFor="tours2">Must Have 3D Tour</label> <br />
						</div>
						<div class="relative mb-[25px] bg-white col-span-12  ">
							<h5 className="font-bold text-base text-black">Square Feet</h5>
							<select className="w-[48%] mr-[1%] nice-select font-light leading-4 placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white appearance-none cursor-pointer" name="min square feet" id="min">
								<option value="0">No Min</option>
								<option value="1">500</option>
								<option value="2">750</option>
								<option value="3">1000</option>
								<option value="4">1250</option>
								<option value="5">1500</option>
								<option value="6">1750</option>
								<option value="7">2000</option>
								<option value="8">2250</option>
								<option value="9">2500</option>
								<option value="10">2750</option>
								<option value="11">3000</option>
								<option value="12">3500</option>
								<option value="13">4000</option>
								<option value="14">4500</option>
								<option value="15">5000</option>
								<option  value="16">7500</option>
							</select>
							<select className="w-[48%] ml-[1%] nice-select font-light leading-4 placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white appearance-none cursor-pointer" name="max square feet" id="max">
								<option value="1">500</option>
								<option value="2">750</option>
								<option value="3">1000</option>
								<option value="4">1250</option>
								<option value="5">1500</option>
								<option value="6">1750</option>
								<option value="7">2000</option>
								<option value="8">2250</option>
								<option value="9">2500</option>
								<option value="10">2750</option>
								<option value="11">3000</option>
								<option value="12">3500</option>
								<option value="13">4000</option>
								<option value="14">4500</option>
								<option value="15">5000</option>
								<option value="16">7500</option>
								<option selected Value="0">No Max</option>
							</select>
						</div>
						<div class="relative mb-[25px] bg-white col-span-12  ">
							<h5 className="font-bold text-base text-black">Plot Size</h5>
							<select className="w-[48%] mr-[1%] nice-select font-light leading-4 placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white appearance-none cursor-pointer" name="min plot size" id="plotMin">
								<option value="0">No Min</option>                                            
								<option value="1">1000</option>                                            
								<option value="2">2000</option>                                            
								<option value="3">3000</option>                                            
								<option value="4">4000</option>                                            
								<option value="5">5000</option>                                            
								<option value="6">7500</option>
								<option value="7">&frac14;acres/ 10,890sqft</option>                                            
								<option value="8">&#189; acres </option>                                            
								<option value="9">1 acre</option>                                            
								<option value="10">2 acre</option>                                            
								<option value="11">5 acre</option>                                            
								<option value="12">10 acre</option>                                            
								<option value="13">20 acre</option>
								<option value="14">50 acre</option>                                            
								<option value="15">100 acre</option>
							</select>
							<select className="w-[48%] ml-[1%] nice-select font-light leading-4 placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white appearance-none cursor-pointer" name="min plot size" id="plotmax">
								<option value="1">1000</option>                                            
								<option value="2">2000</option>                                            
								<option value="3">3000</option>                                            
								<option value="4">4000</option>                                            
								<option value="5">5000</option>                                            
								<option value="6">7500</option>
								<option value="7">&frac14;acres/ 10,890sqft</option>                                            
								<option value="8">&#189; acres </option>                                            
								<option value="9">1 acre</option>                                            
								<option value="10">2 acre</option>                                            
								<option value="11">5 acre</option>                                            
								<option value="12">10 acre</option>                                            
								<option value="13">20 acre</option>
								<option value="14">50 acre</option>                                            
								<option value="15">100 acre</option>
								<option selected value="0">No Max</option>   
							</select>
						</div>
						<div class="relative mb-[25px] bg-white col-span-12  ">
							<h5 className="font-bold text-base text-black">Year Built</h5>
							<input className="font-light w-[48%] mr-[1%] leading-[1] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white" type="text" placeholder="No Min" />
							<input className="font-light w-[48%] ml-[1%] leading-[1] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white" type="text" placeholder="No Max" />
						</div>
						<div class="relative mb-[25px] bg-white col-span-12  ">
							<h5 className="font-bold text-base text-black">Basement</h5>
							<input type="checkbox" name="basement" id="basement" />
							<label className="ml-[5px] cursor-pointer text-[14px] font-semibold" htmlFor="basement">Has Basement</label>
						</div>
						<div class="relative mb-[25px] bg-white col-span-12  ">
							<h5 className="font-bold text-base text-black">Number of Stories</h5>
							<input type="checkbox" name="Storey" id="Storey" />
							<label className="ml-[5px] cursor-pointer text-[14px] font-semibold" htmlFor="Storey">Single Storey Only</label>
						</div>
						<div class="relative mb-[25px] bg-white col-span-12  ">
							<h5 className="font-bold text-base text-black">Senior Living</h5>
							<input type="checkbox" name="senior" id="seniorHide" />
							<label className="ml-[5px] cursor-pointer text-[14px] font-semibold" htmlFor="seniorHide">Hide 55+ communities</label>
						</div>
						<div class="relative mb-[25px] bg-white col-span-12  ">
							<h5 className="font-bold text-base text-black">Other Amenities</h5>
							<input type="checkbox" name="AC" id="amenities1" />
							<label className="ml-[5px] cursor-pointer text-[14px] font-semibold" htmlFor="amenities1">Must Have A/C</label> <br />
							<input type="checkbox" name="pool" id="amenities2" />
							<label className="ml-[5px] cursor-pointer text-[14px] font-semibold" htmlFor="amenities2">Must Have Pool</label> <br />
							<input type="checkbox" name="Waterfront" id="amenities3" />
							<label className="ml-[5px] cursor-pointer text-[14px] font-semibold" htmlFor="amenities3">Must Have Waterfront</label> <br />
						</div>
						<div class="relative mb-[25px] bg-white col-span-12  ">
							<h5 className="font-bold text-base text-black">View</h5>
							<input type="checkbox" name="city" id="view1" />
							<label className="ml-[5px] cursor-pointer text-[14px] font-semibold" htmlFor="city">City</label> <br />
							<input type="checkbox" name="mountain" id="view2" />
							<label className="ml-[5px] cursor-pointer text-[14px] font-semibold" htmlFor="mountain">Mountain</label> <br />
							<input type="checkbox" name="park" id="view3" />
							<label className="ml-[5px] cursor-pointer text-[14px] font-semibold" htmlFor="park">Park</label> <br />
							<input type="checkbox" name="Water" id="view4" />
							<label className="ml-[5px] cursor-pointer text-[14px] font-semibold" htmlFor="Water">Water</label> <br />
						</div>
						<div class="relative mb-[25px] bg-white col-span-12  ">
							<h5 className="font-bold text-base text-black">Keywords</h5>
							<input className="font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] pl-[40px] pr-[20px] py-[8px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] bg-white" type="text" placeholder="MLS #, yard, etc" />
						</div>
						<div className="relative mb-[25px] bg-white col-span-6 ">
							<button type="" className="w-full inline-block z-[1] before:rounded-md before:block before:absolute before:left-auto before:right-0 before:inset-y-0 before:z-[-1] before:bg-secondary before:w-0 hover:before:w-full hover:before:left-0 hover:before:right-auto before:transition-all leading-none px-[30px] py-[15px] capitalize font-medium text-white text-[14px] xl:text-[16px] relative after:block after:absolute after:inset-0 after:z-[-2] after:bg-primary after:rounded-md after:transition-all">Filter</button>
						</div>
						<div className="relative mb-[25px] bg-white col-span-6 ">
							<button type="" className="w-full inline-block z-[1]  before:rounded-md before:block before:absolute before:left-auto before:right-0 before:inset-y-0 before:z-[-1] before:bg-secondary before:w-0 hover:before:w-full hover:before:left-0 hover:before:right-auto before:transition-all leading-none px-[30px] py-[15px] capitalize font-medium text-primary text-[14px] xl:text-[16px] relative after:border-primary after:border after:block after:absolute after:inset-0 after:z-[-2] after:bg-white after:rounded-md after:transition-all">Reset All</button>
						</div>
					</div>
				</div>
				<div className="relative mb-[25px] bg-white col-span-6 md:col-span-6 lg:col-span-2 ">
					<button type="" className="w-full inline-block z-[1] before:rounded-md before:block before:absolute before:left-auto before:right-0 before:inset-y-0 before:z-[-1] before:bg-secondary before:w-0 hover:before:w-full hover:before:left-0 hover:before:right-auto before:transition-all leading-none px-[30px] py-[15px] capitalize font-medium text-white text-[14px] xl:text-[16px] relative after:block after:absolute after:inset-0 after:z-[-2] after:bg-primary after:rounded-md after:transition-all">Filter</button>
				</div>
				
			</form>
		</div>
	)
}

export default TopFilter