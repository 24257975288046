import React from "react";

import HeaderTop from '../HeaderTop'
import BreadCrumb from "../BreadCrumb"
import Testimonial from "./Testimonial";

import NewsLetter from '../NewsLetter'

import BreadCrumbs from "../data/breadcrumbs.json";
import Helping from "./Helping";
import FeaturesOffered from "./FeaturesOffered";
function Seller(){
	const BreadCrumbData = BreadCrumbs.seller;
	return(
		<>
			<HeaderTop />
			<BreadCrumb {...BreadCrumbData}/>
			<Testimonial />
			<Helping />
			<FeaturesOffered />
			<NewsLetter />

		</>
	)
}

export default Seller;