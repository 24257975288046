import React from "react";

import Dollar from "../../images/icon/dollar.png"
import DebtTrap from "../../images/icon/debt_trap.png"
import CreditScore from "../../images/icon/credit_score.png"
import Consult from "../../images/icon/consult.png"
function YouShouldDo(){
	return(
		<section class="about-section pt-[80px] lg:pt-[120px]">
            <div class="container">
                <div class="grid grid-cols-12 gap-x-[30px] mb-[-30px]">
                    <div class="col-span-12  mb-[30px] lg:order-1">
                        <div class=" mx-auto">
                            <h2 class="font-lora text-primary text-[24px] sm:text-[30px] leading-[1.277] xl:text-xl  mb-3 font-medium text-center">Things You Should Do Before Buying a Property<span class="sp-brand">.</span></h2>
                            <p class="">While there are various aspects you should pay attention to before buying a property, here are some of the inevitable ones you must follow to be on the safe side and save a lot.</p>
                        </div>
                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-x-[20px] -mb-[30px] mt-[45px]">
                            <div class="mb-[30px] lg:mb-10 border border-[#FFF6F0] p-[20px] rounded-[8px] transition-all hover:drop-shadow-[0px_10px_20px_rgba(0,0,0,0.1)] hover:bg-[#E8F1FF] hover:border-[#E8F1FF]">
                                <img src={Dollar} class="max-w-[38px] self-start mb-[15px] mx-auto py-4" loading="lazy" width="50" height="50" alt="about Image" />
                                <div>
                                    <h3 class="font-lora text-primary text-base capitalize mb-[5px]">Don’t Spend Extravagantly</h3>
                                    <p class="text-[14px]">
                                        The first and foremost thing you should do after deciding to buy a home is stop spending on the things you can live without. 
                                        Managing your finances at this stage will definitely help you save more for the ultimate moment.
                                    </p>
                                </div>

                            </div>
                            <div class="mb-[30px] lg:mb-10 border border-[#FFF6F0] p-[20px] rounded-[8px] transition-all hover:drop-shadow-[0px_10px_20px_rgba(0,0,0,0.15)] hover:bg-[#E8F1FF] hover:border-[#E8F1FF]">
                                <img src={DebtTrap} class="max-w-[38px] self-start mb-[15px] mx-auto py-4" loading="lazy" width="50" height="50" alt="about Image" />
                                <div>
                                    <h3 class="font-lora text-primary text-base capitalize mb-[5px]">Don’t Get Into the Debt Trap</h3>
                                    <p class="text-[14px]">
                                        As a famous proverb goes, you should better go to bed hungry than to wake up in debt. 
                                        And this aspect becomes more crucial when you’re going to buy a property. So don’t increase your debt at any cost to avoid unwarranted consequences.
                                        
                                    </p>
                                </div>

                            </div>
                            <div class="mb-[30px] lg:mb-10 border border-[#FFF6F0] p-[20px] rounded-[8px] transition-all hover:drop-shadow-[0px_10px_20px_rgba(0,0,0,0.1)] hover:bg-[#E8F1FF] hover:border-[#E8F1FF]">
                                <img src={CreditScore} class="max-w-[38px] self-start mb-[15px] mx-auto py-4" loading="lazy" width="50" height="50" alt="about Image" />
                                <div>
                                    <h3 class="font-lora text-primary text-base capitalize mb-[5px]">Take Care of Your Credit Score</h3>
                                    <p class="text-[14px]">
                                        Your credit score can determine a lot more than you can imagine. It not only uncovers your financial spending habits but also showcases your creditworthiness.
                                        So, keep the score high to build a solid reputation.

                                    </p>
                                </div>

                            </div>
                            <div class="mb-[30px] lg:mb-10 border border-[#FFF6F0] p-[20px] rounded-[8px] transition-all hover:drop-shadow-[0px_10px_20px_rgba(0,0,0,0.1)] hover:bg-[#E8F1FF] hover:border-[#E8F1FF]">
                                <img src={Consult} class="max-w-[38px] self-start mb-[15px] mx-auto py-4" loading="lazy" width="50" height="50" alt="about Image" />
                                <div>
                                    <h3 class="font-lora text-primary text-base capitalize mb-[5px]">Consult With Oren Access</h3>
                                    <p class="text-[14px]">
                                        Taking worthwhile suggestions from Oren Access will go a long way in transforming your buying ideas into reality. Apart from determining your spending power,
                                        we’ll also guide you on all buying aspects to make your journey seamless. Reach out to us NOW to have a better tomorrow.
                                    </p>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section>
	)
}

export default YouShouldDo;