import React from "react";

function BrandSticker({info}){
	const imgSrc = `/images/icon/${info.image}`

	return(
		<div className="w-36 shadow-sm hover:bg-[honeydew] hover:animate-wiggle">
			<div className="mx-auto px-8 py-4">
				<img src={imgSrc} className="w-auto h-16 block mx-auto" loading="lazy" alt="" />
			</div>
			<h6 className="text-center font-semibold ">{info.name}</h6>
		</div>
	)
}

export default BrandSticker;