import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom"

import './css/plugins/magnific-popup.css';
import './css/plugins/nice-select.css';
import './css/plugins/swiper-bundle.min.css';


import './css/style.css';

import './css/sp-style.css';
import './css/output.css';

import Layout from './Layout'
import Home from './components/Home/Home'
import Buyer from './components/Buyer/Buyer'
import Login from './components/Login/Login'
import Register from './components/Register/Register'
import Seller from './components/Seller/Seller'
import RealEstateAgent from './components/Professionals/Real_Estate_Agent/RealEstateAgent'
import HomeInspector from './components/Professionals/Home_Inspector/HomeInspector'
import Agent from './components/Professionals/Agent/Agent'

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
	{
		path : '/',
		element : <Layout />,
		children : [
			{
				path : '',
                element : <Home />
			},
			{
				path : 'buyer',
                element : <Buyer />
			},
			{
				path : 'login',
				element : <Login />
			},
			{
				path : 'register',
				element : <Register />
			},
			{
				path :'seller',
                element : <Seller />
			},
			
		]

	},
	{
		path : 'professional',
		element : <Layout />,
		children : [
			{
				path :'real-estate-agent',
                element : <RealEstateAgent />
			},
			{
                path : 'home-inspector',
                element : <HomeInspector />
            },
			{
				path : 'agent',
				element : <Agent />
			}
		]
	}
])

root.render(
	<React.StrictMode>
		<RouterProvider router={router}/>
	</React.StrictMode>
);