import React from "react";
import Video2 from '../images/video/video-2.png'
import Shape4 from '../images/video/shape-4.png'
function VideoSection(){
	return(
		<section className="video-section mt-12">
            <div className="container">
                <div className="relative flex items-center flex-col-reverse lg:flex-row justify-between">
                    <div className="lg:max-w-[737px] max-w-full w-full">
                        <div className="rounded-[24px] z-[1]">
                            <div className="relative">
                                <div className=" relative overflow-hidden" data-relative-input="true">
                                    <img data-depth="0.02" src={Video2} className="rounded-[24px] w-full" loading="lazy" width="507" height="349" alt="video image" />
                                </div>
                                <a href="https://www.youtube.com/watch?v=BDXHtLqFUf4" className="play-button bg-white text-white hover:text-primary absolute left-0 right-0 mx-auto top-1/2 -translate-y-1/2 hover:scale-105 hover:bg-primary w-[70px] h-[70px] md:w-[90px] md:h-[90px] flex 
                flex-wrap z-[5] items-center justify-center opacity-100 shadow-[0px 4px 4px rgba(0, 0, 0, 0.25)] transition-all rounded-full group
                
                before:block before:absolute  before:bg-white before:opacity-80 before:shadow-[0px 4px 4px rgba(0, 0, 0, 0.25)] hover:before:bg-primary hover:before:opacity-80 md:before:w-[110px] md:before:h-[110px] before:w-[90px] before:h-[90px] before:rounded-full before:z-[-1]
                " aria-label="play button">
                                    <svg width="31" height="32" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className="stroke-primary group-hover:stroke-white" d="M1.63861 10.764V6.70324C1.63861 1.66145 5.20893 -0.403178 9.57772 2.11772L13.1024 4.14812L16.6271 6.17853C20.9959 8.69942 20.9959 12.8287 16.6271 15.3496L13.1024 17.38L9.57772 19.4104C5.20893 21.9313 1.63861 19.8666 1.63861 14.8249V10.764Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div className="relative max-w-full lg:max-w-[510px] w-full ml-0 lg:-ml-[80px] bg-primary py-10 lg:py-20 px-10 rounded-[20px] lg:mb-0 mb-8">
                        <span className="text-secondary text-tiny inline-block mb-2">Take a video tour</span>
                        <h2 className="font-lora text-white text-[24px] sm:text-[26px] leading-[1.277] xl:text-[34px] mb-[10px] font-medium">Watch the video for taking your decision easily<span className="text-secondary">.</span></h2>
                        <a href="#" className="flex flex-wrap items-center text-secondary text-tiny mt-[20px]">View all
                            <svg className="ml-[10px]" width="26" height="11" viewBox="0 0 26 11" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.0877 0.69303L24.2075 5.00849H0V5.99151H24.2075L20.0877 10.307L20.7493 11L26 5.5L20.7493 0L20.0877 0.69303Z" fill="currentColor"></path>
                            </svg>
                        </a>
                        <div>
                            <img src={Shape4} className="absolute bottm-[30px] right-[30px] z-[1]" alt="shape image" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
	)
}

export default VideoSection;