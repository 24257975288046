import CMAIcon from "../../images/sellers/sellers-icon-1.png"
import BuyIcon from "../../images/sellers/sellers-icon-2.png"
import MonitorIcon from "../../images/sellers/sellers-icon-3.png"
function Helping(){
	return(
		<section className=" pb-[80px] ">
            <div className="container">
                <div className="grid grid-cols-12">
                    <div className="col-span-12">
                        <div className="mb-[30px] lg:mb-[60px] text-center">
                            <span className="text-secondary text-tiny inline-block mb-2">Our Help</span>
                            <h2 className="font-lora text-primary text-[24px] sm:text-[30px] xl:text-xl font-medium">
                                How Oren Access Is Helping Sellers<span className="sp-brand">?</span></h2>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3  gap-[30px]">

                    <div className="relative group">
                        <a href="" className="block">
                            <img src={CMAIcon} className="w-full h-full block mx-auto rounded-[6px_6px_0px_0px]" loading="lazy" width="270" height="290" alt="Sale Property"/>
                            <div className="drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] hover:drop-shadow-[0px_8px_20px_rgba(0,0,0,0.15)] bg-[#FFFDFC] rounded-[0px_0px_6px_6px] px-[25px] py-[25px]">
                                <h3 className="font-lora font-normal text-[24px] xl:text-lg text-primary group-hover:text-secondary transition-all mb-[5px]">CMA Analysis<span className="sp-brand group-hover:text-primary">.</span> </h3>
                                <p className="font-light text-tiny">
                                    The first step in your journey of selling a home is assessing the accurate market value of your property. 
                                    With several years of experience in property evaluation under our belt, we can conduct an in-depth CMA analysis to help you find how much you should demand for your property.
                                </p>
                            </div>
                        </a>
                    </div>

                    <div className="relative group">
                        <a href="" className="block">
                            <img src={BuyIcon} className="w-full h-full block mx-auto rounded-[6px_6px_0px_0px]" loading="lazy" width="270" height="290" alt="Buy Property"/>
                            <div className="drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] hover:drop-shadow-[0px_8px_20px_rgba(0,0,0,0.15)] bg-[#FFFDFC] rounded-[0px_0px_6px_6px] px-[25px] py-[25px]">
                                <h3 className="font-lora font-normal text-[24px] xl:text-lg text-primary group-hover:text-secondary transition-all mb-[5px]">Buy Property<span className="sp-brand group-hover:text-primary">.</span> </h3>
                                <p className="font-light text-tiny">
                                    Oren Access will also help you market your property on the internet so that you can find genuine buyers in the blink of an eye.
                                    We take a birds-eye-view of your requirements, and finally advertise your property in the area where chances of the sale are highest.
                                </p>
                            </div>
                        </a>
                    </div>

                    <div className="relative group">
                        <a href="" className="block">
                            <img src={MonitorIcon} className="w-full h-full block mx-auto rounded-[6px_6px_0px_0px]" loading="lazy" width="270" height="290" alt="Rent Property"/>
                            <div className="drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] hover:drop-shadow-[0px_8px_20px_rgba(0,0,0,0.15)] bg-[#FFFDFC] rounded-[0px_0px_6px_6px] px-[25px] py-[25px]">
                                <h3 className="font-lora font-normal text-[24px] xl:text-lg text-primary group-hover:text-secondary transition-all mb-[5px]">Continuous Monitoring<span className="sp-brand group-hover:text-primary">.</span> </h3>
                                <p className="font-light text-tiny">
                                    Listing your property with an Oren Access agent means that you’ll have complete control over your account.
                                    From customizing your property details and evaluating the number of visits to checking the emails of potential buyers, you’ll control everything without any disruptions.
                                </p>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </section>
	)
}

export default Helping;