import {React, useState }from "react";
import { NavLink } from "react-router-dom";
import Interior from "../../images/contact/image2.png"

function RegisterForm(){
	const {advReg, setadvReg} = useState(false)
	const handleChange = () =>{
		setadvReg(!advReg)
	}

	return(
		<div className="py-[80px] lg:py-[120px]">
			<div className="container">
				<form action="#">
					<div className="grid grid-cols-12 gap-x-[30px] mb-[-30px]">
						<div className="col-span-12 lg:col-span-6 mb-[30px]">
							<h2 className="font-lora text-primary text-[24px] sm:text-[30px] leading-[1.277] xl:text-xl mb-[15px] font-medium">
								Create Account<span className="text-secondary">.</span></h2>

							<p className="max-w-[465px] mb-[50px]">
								Create an account for a personalized search experience. 
								Save favorite listings, get notified about new properties, and connect with our local experts to find your dream home in USA.
							</p>
							<div className="grid grid-cols-12 gap-x-[20px] gap-y-[35px]">

								<div className="col-span-12">
									<input className="font-light w-full sm:w-[400px] leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] " type="text" placeholder="Full Name" />
								</div>

								<div className="col-span-12">
									<input className="font-light w-full sm:w-[400px] leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] " type="email" placeholder="Email" />
								</div>

								<div className="col-span-12">
									<input className="font-light w-full sm:w-[400px] leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] " type="tel" placeholder="Phone" minlength="10" maxlength="10" />
								</div>
								<div className="col-span-12">
									<input className="font-light w-full sm:w-[400px] leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] " type="number" placeholder="Zip/Postal" />
								</div>
								<div className="col-span-12">
									<input className="font-light w-full sm:w-[400px] leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] " type="password" placeholder="Password" />
								</div>
								<div className="col-span-12">
									<input className="font-light w-full sm:w-[400px] leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] " type="password" placeholder="Confirm Password" />

									<div className="flex flex-wrap items-center justify-between w-full sm:w-[400px]">
										<div className="flex flex-wrap mt-8 items-center">
											<input type="checkbox" id="checkboxLandlord" name="Remember me" onChange={handleChange}/>
											<label for="checkboxLandlord" className="ml-2 cursor-pointer"> I am landlord or industry professional</label><br />
										</div>
									</div>
								</div>
								<div id="advancedReg" className={`${advReg ? '' : 'hidden'}hidden col-span-12`}>
									<div className="relative mb-[25px] bg-white w-full">
                                        <select className="font-light w-full sm:w-[400px] leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] pr-6">
                                            <option selected value="0">Professional Type</option>
                                            <option value="1">Real Estate Agent</option>
                                            <option value="2">Broker</option>
                                            <option value="3">Mortgage Lended</option>
											<option value="4">Home Improvement</option>
											<option value="5">Landlord</option>
											<option value="6">Photographer</option>
											<option value="7">Home Builder</option>
											<option value="8">Home Inspector</option>
											<option value="9">Property Manager</option>
											<option value="7">Other Real Estate Professional</option>
                                        </select>
                                    </div>
								</div>
								<div className="col-span-12">
									<div className="flex flex-wrap items-center">
										<button type="submit" className="before:rounded-md before:block before:absolute before:left-auto before:right-0 before:inset-y-0 before:-z-[1] before:bg-secondary before:w-0 hover:before:w-full hover:before:left-0 hover:before:right-auto before:transition-all leading-none px-[40px] py-[15px] capitalize font-medium text-white text-[14px] xl:text-[16px] relative after:block after:absolute after:inset-0 after:-z-[2] after:bg-primary after:rounded-md after:transition-all">Register</button>
										<p className="ml-[40px]">Already have an Account? <NavLink to="/login" className="text-secondary">Login</NavLink></p>
									</div>
									<p className="my-4 font-light text-sm">By Submitting, I accept Orenaccess's <a href="" className="text-blue-700">terms and conditions</a>.</p>
								</div>
							</div>
						</div>

						<div className="col-span-12 lg:col-span-6 mb-[30px]">
							<img src={Interior} className="w-full lg:max-w-[538px] h-auto rounded-[10px]" width="546" height="668" alt="image" />
						</div>
					</div>
				</form>

			</div>
		</div>
	)
}

export default RegisterForm;