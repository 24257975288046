import React from "react";

import Location from '../../../images/icon/location.svg';
import Property from '../../../images/icon/property.svg';
import Dollar from '../../../images/icon/dollar-circle.svg';
import Area from '../../../images/icon/area.svg';
import Search from '../../../images/icon/search-outline.svg';
function Buy({ foldedTab }){
	return(
		<div className="bg-white border border-solid border-[#016450] border-opacity-25 rounded-bl-[15px] rounded-br-[15px] rounded-tr-[15px] px-[15px] sm:px-[30px] py-[40px] active">
			<form action="#">
				<div className="advanced-searrch flex flex-wrap items-center -mb-[45px]">

					<div className="advanced-searrch-list flex items-center lg:border-r lg:border-[#D6D4D4] lg:mr-[40px] xl:mr-[50px] last:mr-0 last:border-r-0 mb-[45px]">
						<div className="mr-4 self-start shrink-0">
							<img src={Location} width="24" height="24" alt="svg icon" />
						</div>
						<div className="flex-1">
							<label htmlFor="location" className="font-lora block capitalize text-primary text-[17px] xl:text-[24px] leading-none mb-1">Location</label>
							<input id="location" type="text" placeholder="Choose location" className="text-tiny placeholder:text-body leading-none font-light pr-3 focus:outline-none w-full" />
						</div>
					</div>

					<div className="advanced-searrch-list flex items-center lg:border-r lg:border-[#D6D4D4] lg:mr-[40px] xl:mr-[50px] last:mr-0 last:border-r-0 mb-[45px]">
						<div className="mr-4 self-start shrink-0">
							<img src={Property} width="24" height="24" alt="svg icon" />
						</div>
						<div className="flex-1">
							<label htmlFor="property" className="font-lora block capitalize text-primary text-[17px] xl:text-[24px] leading-none mb-1">Property Type</label>
							<select name="property" id="property" className="nice-select appearance-none bg-transparent text-tiny font-light cursor-pointer">
								<option defaultValue="0" value="0">Duplex House</option>
								<option value="1">Duplex House 1</option>
								<option value="2">Duplex House 2</option>
								<option value="3">Duplex House 3</option>
							</select>
						</div>
					</div>

					<div className="advanced-searrch-list flex items-center lg:border-r lg:border-[#D6D4D4] lg:mr-[40px] xl:mr-[50px] last:mr-0 last:border-r-0 mb-[45px]">
						<div className="mr-4 self-start shrink-0">
							<img src={Dollar} width="24" height="24" alt="svg icon" />
						</div>
						<div className="flex-1">
							<label htmlFor="price" className="font-lora block capitalize text-primary text-[17px] xl:text-[24px] leading-none mb-1">Price Range</label>
							<select name="price" id="price" className="nice-select appearance-none bg-transparent text-body text-tiny font-light cursor-pointer">
								<option defaultValue="0" value="0">1500 USD</option>
								<option value="1">1600 USD</option>
								<option value="2">1700 USD</option>
								<option value="3">1800 USD</option>
							</select>
						</div>

					</div>

					<div className="advanced-searrch-list flex items-center lg:border-r lg:border-[#D6D4D4] lg:mr-[40px] xl:mr-[50px] last:mr-0 last:border-r-0 mb-[45px] relative">

						<div className="mr-4 self-start shrink-0">
							<img src={Area} width="24" height="24" alt="svg icon" />
						</div>

						<div className="flex-1">
							<label htmlFor="property-size" className="font-lora block capitalize text-primary text-[17px] xl:text-[24px] leading-none mb-1">Property Size</label>
							<select name="property-size" id="property-size" className="nice-select appearance-none bg-transparent text-tiny font-light cursor-pointer">
								<option defaultValue="0" value="0">2500 Sqft</option>
								<option value="1">2600 Sqft</option>
								<option value="2">2700 Sqft</option>
								<option value="3">2800 Sqft</option>
							</select>
						</div>
						<button className="search-btn absolute right-0 lg:right-[-60px] xl:right-[-70px]">
							<img src={Search} className="max-w-[30px] xl:w-auto" width="40" height="40" alt="svg icon" />
							<span className="hidden">Search Properties</span>
						</button>
					</div>

				</div>

				<div className={` ${foldedTab ? 'advanced-searrch-hidden' : ''} flex flex-wrap items-center mt-[45px] -mb-[45px]`}>

					<div className="advanced-searrch-list flex items-center lg:border-r lg:border-[#D6D4D4] lg:mr-[40px] xl:mr-[50px] last:mr-0 last:border-r-0 mb-[45px] search-list">
						<div className="mr-4 self-start shrink-0">
							<img src={Location} width="24" height="24" alt="svg icon" />
						</div>
						<div className="flex-1">
							<label htmlFor="bedrooms10" className="font-lora block capitalize text-primary text-[17px] xl:text-[24px] leading-none mb-1">Bedrooms</label>
							<select name="property" id="bedrooms10" className="nice-select appearance-none bg-transparent text-tiny font-light cursor-pointer">
								<option defaultValue="0" value="0">Bedrooms</option>
								<option value="1">kitchen</option>
								<option value="2">dinning rooms</option>
								<option value="3">Duplex House 3</option>
							</select>
						</div>
					</div>

					<div className="advanced-searrch-list flex items-center lg:border-r lg:border-[#D6D4D4] lg:mr-[40px] xl:mr-[50px] last:mr-0 last:border-r-0 mb-[45px] search-list">
						<div className="mr-4 self-start shrink-0">
							<img src={Property} width="24" height="24" alt="svg icon" />
						</div>
						<div className="flex-1">
							<label htmlFor="property9" className="font-lora block capitalize text-primary text-[17px] xl:text-[24px] leading-none mb-1">Bathrooms</label>
							<select name="property" id="property9" className="nice-select appearance-none bg-transparent text-tiny font-light cursor-pointer">
								<option defaultValue="0" value="0">Duplex House</option>
								<option value="1">Duplex House 1</option>
								<option value="2">Duplex House 2</option>
								<option value="3">Duplex House 3</option>
							</select>
						</div>
					</div>

					<div className="advanced-searrch-list flex items-center lg:border-r lg:border-[#D6D4D4] lg:mr-[40px] xl:mr-[50px] last:mr-0 last:border-r-0 mb-[45px] search-list">
						<div className="mr-4 self-start shrink-0">
							<img src={Dollar} width="24" height="24" alt="svg icon" />
						</div>
						<div className="flex-1">
							<label htmlFor="garage" className="font-lora block capitalize text-primary text-[17px] xl:text-[24px] leading-none mb-1">Garage</label>
							<select name="garage" id="garage" className="nice-select appearance-none bg-transparent text-tiny font-light cursor-pointer">
								<option defaultValue="0" value="0">2 Garage</option>
								<option value="1">2 Garage</option>
								<option value="2">3 Garage</option>
								<option value="3">4 Garage</option>
							</select>
						</div>

					</div>

					<div className="advanced-searrch-list flex items-center lg:border-r lg:border-[#D6D4D4] lg:mr-[40px] xl:mr-[50px] last:mr-0 last:border-r-0 mb-[45px] search-list">
						<button className="search-properties-btn">
							Search Properties
						</button>
					</div>

				</div>


			</form>
		</div>
	)
}

export default Buy