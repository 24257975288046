function TeamMember({member}){
	return(
		<div class="text-center group mb-[30px]">
			<div class="relative rounded-[6px_6px_0px_0px]">
				<a href="">
					<img src={`/images/agents/${member.image}`} class="w-full h-auto block mx-auto" loading="lazy"  alt="Albert S. Smith"/>
				</a>

			</div>

			<div class="bg-[#FFFDFC] z-[1] drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] rounded-[0px_0px_6px_6px] px-3 md:px-[15px] py-[20px] border-b-[6px] border-primary transition-all duration-500 before:transition-all before:duration-300 group-hover:border-secondary relative">
				<h3><a href="" class="font-lora font-normal text-base text-primary group-hover:text-secondary">{member.name}</a></h3>
				<p class="font-normal text-[14px] leading-none capitalize mt-[5px] group-hover:text-body">{member.sales}</p>
				<p class="font-normal text-[14px] leading-none capitalize mt-[5px] group-hover:text-body">{member.priceRange}</p>
				<div class="flex flex-wrap mt-4 justify-center">
					<span class="mx-[4px]">
						<svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14.1887 5.51714L10.2215 4.94058L8.4481 1.34527C8.39966 1.24683 8.31997 1.16714 8.22154 1.11871C7.97466 0.996832 7.67466 1.09839 7.55122 1.34527L5.77779 4.94058L1.8106 5.51714C1.70123 5.53277 1.60123 5.58433 1.52466 5.66246C1.4321 5.75759 1.3811 5.88558 1.38286 6.0183C1.38461 6.15103 1.43899 6.27762 1.53404 6.37027L4.40435 9.16871L3.72623 13.1203C3.71032 13.2122 3.72049 13.3067 3.75559 13.3932C3.79068 13.4796 3.84929 13.5545 3.92477 13.6093C4.00025 13.6641 4.08958 13.6967 4.18263 13.7033C4.27568 13.71 4.36873 13.6904 4.45123 13.6468L7.99966 11.7812L11.5481 13.6468C11.645 13.6984 11.7575 13.7156 11.8653 13.6968C12.1372 13.65 12.32 13.3921 12.2731 13.1203L11.595 9.16871L14.4653 6.37027C14.5434 6.29371 14.595 6.19371 14.6106 6.08433C14.6528 5.81089 14.4622 5.55777 14.1887 5.51714Z" fill="#B39359"></path>
						</svg>
					</span>
					<span class="mx-[4px]">
						<svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14.1887 5.51714L10.2215 4.94058L8.4481 1.34527C8.39966 1.24683 8.31997 1.16714 8.22154 1.11871C7.97466 0.996832 7.67466 1.09839 7.55122 1.34527L5.77779 4.94058L1.8106 5.51714C1.70123 5.53277 1.60123 5.58433 1.52466 5.66246C1.4321 5.75759 1.3811 5.88558 1.38286 6.0183C1.38461 6.15103 1.43899 6.27762 1.53404 6.37027L4.40435 9.16871L3.72623 13.1203C3.71032 13.2122 3.72049 13.3067 3.75559 13.3932C3.79068 13.4796 3.84929 13.5545 3.92477 13.6093C4.00025 13.6641 4.08958 13.6967 4.18263 13.7033C4.27568 13.71 4.36873 13.6904 4.45123 13.6468L7.99966 11.7812L11.5481 13.6468C11.645 13.6984 11.7575 13.7156 11.8653 13.6968C12.1372 13.65 12.32 13.3921 12.2731 13.1203L11.595 9.16871L14.4653 6.37027C14.5434 6.29371 14.595 6.19371 14.6106 6.08433C14.6528 5.81089 14.4622 5.55777 14.1887 5.51714Z" fill="#B39359"></path>
						</svg>
					</span>
					<span class="mx-[4px]">
						<svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14.1887 5.51714L10.2215 4.94058L8.4481 1.34527C8.39966 1.24683 8.31997 1.16714 8.22154 1.11871C7.97466 0.996832 7.67466 1.09839 7.55122 1.34527L5.77779 4.94058L1.8106 5.51714C1.70123 5.53277 1.60123 5.58433 1.52466 5.66246C1.4321 5.75759 1.3811 5.88558 1.38286 6.0183C1.38461 6.15103 1.43899 6.27762 1.53404 6.37027L4.40435 9.16871L3.72623 13.1203C3.71032 13.2122 3.72049 13.3067 3.75559 13.3932C3.79068 13.4796 3.84929 13.5545 3.92477 13.6093C4.00025 13.6641 4.08958 13.6967 4.18263 13.7033C4.27568 13.71 4.36873 13.6904 4.45123 13.6468L7.99966 11.7812L11.5481 13.6468C11.645 13.6984 11.7575 13.7156 11.8653 13.6968C12.1372 13.65 12.32 13.3921 12.2731 13.1203L11.595 9.16871L14.4653 6.37027C14.5434 6.29371 14.595 6.19371 14.6106 6.08433C14.6528 5.81089 14.4622 5.55777 14.1887 5.51714Z" fill="#B39359"></path>
						</svg>
					</span>
					<span class="mx-[4px]">
						<svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14.1887 5.51714L10.2215 4.94058L8.4481 1.34527C8.39966 1.24683 8.31997 1.16714 8.22154 1.11871C7.97466 0.996832 7.67466 1.09839 7.55122 1.34527L5.77779 4.94058L1.8106 5.51714C1.70123 5.53277 1.60123 5.58433 1.52466 5.66246C1.4321 5.75759 1.3811 5.88558 1.38286 6.0183C1.38461 6.15103 1.43899 6.27762 1.53404 6.37027L4.40435 9.16871L3.72623 13.1203C3.71032 13.2122 3.72049 13.3067 3.75559 13.3932C3.79068 13.4796 3.84929 13.5545 3.92477 13.6093C4.00025 13.6641 4.08958 13.6967 4.18263 13.7033C4.27568 13.71 4.36873 13.6904 4.45123 13.6468L7.99966 11.7812L11.5481 13.6468C11.645 13.6984 11.7575 13.7156 11.8653 13.6968C12.1372 13.65 12.32 13.3921 12.2731 13.1203L11.595 9.16871L14.4653 6.37027C14.5434 6.29371 14.595 6.19371 14.6106 6.08433C14.6528 5.81089 14.4622 5.55777 14.1887 5.51714Z" fill="#B39359"></path>
						</svg>
					</span>
					<span class="mx-[4px]">
						<svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14.1887 5.51714L10.2215 4.94058L8.4481 1.34527C8.39966 1.24683 8.31997 1.16714 8.22154 1.11871C7.97466 0.996832 7.67466 1.09839 7.55122 1.34527L5.77779 4.94058L1.8106 5.51714C1.70123 5.53277 1.60123 5.58433 1.52466 5.66246C1.4321 5.75759 1.3811 5.88558 1.38286 6.0183C1.38461 6.15103 1.43899 6.27762 1.53404 6.37027L4.40435 9.16871L3.72623 13.1203C3.71032 13.2122 3.72049 13.3067 3.75559 13.3932C3.79068 13.4796 3.84929 13.5545 3.92477 13.6093C4.00025 13.6641 4.08958 13.6967 4.18263 13.7033C4.27568 13.71 4.36873 13.6904 4.45123 13.6468L7.99966 11.7812L11.5481 13.6468C11.645 13.6984 11.7575 13.7156 11.8653 13.6968C12.1372 13.65 12.32 13.3921 12.2731 13.1203L11.595 9.16871L14.4653 6.37027C14.5434 6.29371 14.595 6.19371 14.6106 6.08433C14.6528 5.81089 14.4622 5.55777 14.1887 5.51714Z" fill="#B39359"></path>
						</svg>
					</span>
				</div>
			</div>
		</div>
	)
}

export default TeamMember