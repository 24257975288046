import React from "react";

import Service1 from '../../images/service/service1.jpg'
import Service2 from '../../images/service/service2.jpg'
import Service3 from '../../images/service/service3.jpg'
import Service4 from '../../images/service/service4.jpg'

const minH = {
	minHeight : "180px",
}
function WhatWeDo(){
	return(
		<section className="pt-[80px] pb-[120px] lg:py-[120px]">
            <div className="container">
                <div className="grid grid-cols-12">
                    <div className="col-span-12">
                        <div className="mb-[30px] lg:mb-[60px] text-center">
                            <span className="text-secondary text-2xl inline-block mb-2">What We Do</span>
                            <h2 className="font-lora  text-primary text-tiny sm:text-tiny xl:text-tiny font-medium">
                                Leveraging an in-depth understanding of the real estate industry,
                                Oren Access allows buyers, sellers, renters, agents and industry 
                                professionals to come together and share their unique expertise with the right client
                                <span className="text-secondary">.</span>
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-[30px]">
                    <div className="relative group">
                        <a href="" className="block">
                            
                            <img src={Service1} className="w-full h-full block mx-auto rounded-[6px_6px_0px_0px]" loading="lazy" width="270" height="290" alt="Sale Property" />
                            <div className="drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] hover:drop-shadow-[0px_8px_20px_rgba(0,0,0,0.15)] bg-[#FFFDFC] rounded-[0px_0px_6px_6px] px-[25px] py-[25px]">
                                <h3 className="font-lora font-normal text-[24px] xl:text-lg text-primary group-hover:text-secondary transition-all mb-[5px]">Buy a Home<span className="text-secondary group-hover:text-primary">.</span> </h3>
                                <p className="font-light text-tiny" style={minH}>
                                    Buying your dream property hasn’t been easier. Just outline your requirements,
                                    and a whole new world of properties will be in front of you.
                                </p>
                            </div>
                            
                        </a>
                        
                    </div>
                    <div className="relative group">
                        <a href="" className="block">
                            <img src={Service2} className="w-full h-full block mx-auto rounded-[6px_6px_0px_0px]" loading="lazy" width="270" height="290" alt="Buy Property" />
                            <div className="drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] hover:drop-shadow-[0px_8px_20px_rgba(0,0,0,0.15)] bg-[#FFFDFC] rounded-[0px_0px_6px_6px] px-[25px] py-[25px]">
                                <h3 className="font-lora font-normal text-[24px] xl:text-lg text-primary group-hover:text-secondary transition-all mb-[5px]">Sell a Home<span className="text-secondary group-hover:text-primary">.</span> </h3>
                                <p className="font-light text-tiny" style={minH}>
                                    Imagine selling your property to the right buyers without being snatched by the clever agents.
                                    This is exactly what sellers get with Oren Access.
                                </p>
                            </div>
                        </a>
                    </div>
                    <div className="relative group">
                        <a href="" className="block">
                            <img src={Service3} className="w-full h-full block mx-auto rounded-[6px_6px_0px_0px]" loading="lazy" width="270" height="290" alt="Rent Property" />
                            <div className="drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] hover:drop-shadow-[0px_8px_20px_rgba(0,0,0,0.15)] bg-[#FFFDFC] rounded-[0px_0px_6px_6px] px-[25px] py-[25px]">
                                <h3 className="font-lora font-normal text-[24px] xl:text-lg text-primary group-hover:text-secondary transition-all mb-[5px]">Find Rentals<span className="text-secondary group-hover:text-primary">.</span> </h3>
                                <p className="font-light text-tiny" style={minH}>
                                    Oren Access makes the process of finding rental apartments a walk 
                                    in the park by providing a customized search for hundreds of apartments listings.
                                </p>
                            </div>
                        </a>
                    </div>
                    <div className="relative group">
                        <a href="" className="block">
                            <img src={Service4} className="w-full h-full block mx-auto rounded-[6px_6px_0px_0px]" loading="lazy" width="270" height="290" alt="Mortgage" />
                            <div className="drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] hover:drop-shadow-[0px_8px_20px_rgba(0,0,0,0.15)] bg-[#FFFDFC] rounded-[0px_0px_6px_6px] px-[25px] py-[25px]">
                                <h3 className="font-lora font-normal text-[24px] xl:text-lg text-primary group-hover:text-secondary transition-all mb-[5px]">Professionals<span className="text-secondary group-hover:text-primary">.</span> </h3>
                                <p className="font-light text-tiny" style={minH}>
                                    Looking for a professional who can guide you throughout 
                                    your real estate buying and selling journey? We’ve got you covered.
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
	)
}

export default WhatWeDo;