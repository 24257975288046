import React from "react";

function BreadCrumb({ imgUrl, subHead, title, description }){



	return(
		<section className="bg-no-repeat bg-center bg-cover bg-[#FFF6F0] h-[350px] lg:h-[513px] flex flex-wrap items-center relative before:absolute before:inset-0 before:content-[''] before:bg-[#000000] before:opacity-[50%]" style={{background : `url(/images/breadcrumb/${imgUrl})`}}>
            <div className="container">
                <div className="grid grid-cols-12">
                    <div className="col-span-12">
                        <div className="max-w-[800px]  mx-auto text-center text-white relative z-[1]">
                            <div className="mb-5"><span className="text-base block">{subHead}</span></div>
                            <h1 className="font-lora text-[36px] sm:text-[50px] md:text-[68px] lg:text-[50px] leading-tight xl:text-2xl font-medium">{title}</h1>
                            <p className="text-base mt-5 max-w-[500px] mx-auto text-center">{description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
	)
}

export default BreadCrumb;