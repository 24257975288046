function FindLocalAgent(){
	return(
		<section class="bg-no-repeat bg-center bg-cover bg-[#FFF6F0] h-[350px] lg:h-[513px] flex flex-wrap items-center relative before:absolute before:inset-0 before:content-[''] before:bg-[#000000] before:opacity-[30%]" style={{backgroundImage: `url(/images/breadcrumb/find_agent.png)`}}>
			<div class="container">
				<div class="grid grid-cols-12">
					<div class="col-span-12">
						<div class="max-w-[1100px]  mx-auto text-left text-white relative z-[1]">
							<h1 class="font-lora text-[32px] sm:text-[25px] md:text-[30px] lg:text-[36px] leading-tight  font-medium">
								Launch your agent hunt
							</h1>
							<div class="my-5">
								<span class="text-base block ">Connect you with a local Orenaccess Premier Agent who knows your area like the back of their hand.</span>
							</div>
							<button type="submit" class="text-white block font-medium text-[16px] bg-secondary py-[9px] px-[20px] rounded-[5px] hover:bg-white hover:text-primary transition-all ">Connect with local agent</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default FindLocalAgent