
import { Swiper, SwiperSlide } from "swiper/react";


// Import Swiper styles

import Dots from "../../images/testimonial/dots.png";
import PersonPattern from "../../images/testimonial/persone-pattern.png";
import Person21 from "../../images/testimonial/person2-1.png";
import Person2 from "../../images/testimonial/person2.png";



function Testimonial() {


    return (
        <section className="testimonial-section bg-white relative z-[5] py-[80px]">
            <div className="container testimonial-carousel-two relative">
                <div className="scene dots-shape absolute left-0">
                    <img src={Dots} data-depth="0.4" className="z-[1]" width="106" height="129" loading="lazy" alt="shape" />
                </div>
                <div className="grid items-center grid-cols-12 gap-x-[30px]">
                    <div className="col-span-12 relative">
                        <Swiper pagination={{ clickable: true }} autoplay={{ delay: 5000 }} className="swiper rounded-[30px] pb-[40px] md:pb-0">
                            <SwiperSlide className={`swiper-slide  `} >
                                <div className="pl-[50px] xl:pl-[150px]">
                                    <div className="inline-block relative bg-primary text-primary rounded-[30px] z-10">
                                        <img src={Person21} className="w-auto h-auto block mx-auto relative z-[2] thumb" loading="lazy" width="402" height="505" alt="Oliver Stephen" />
                                        <img src={PersonPattern} className="absolute left-[0px]  z-[1]" width="400" height="500" loading="lazy" alt="shape" />
                                    </div>
                                </div>
                                <div className="before:absolute before:top-1/2 before:-translate-y-1/2 before:left-0 before:w-full before:h-[395px] before:content-[''] before:bg-[#F5F9F8] before:rounded-[30px]">
                                    <div className="text-left rounded-[14px] max-w-[100%] sm:max-w-[90%] md:max-w-[560px] mx-auto md:ml-auto absolute top-[65%] sm:top-1/2 left-0 md:left-auto right-0 md:right-[50px] xl:right-0 -translate-y-1/2 px-[20px] md:px-[30px] xl:pl-[0px] xl:pr-[60px] py-[20px] md:py-[30px] z-20 bg-white xl:bg-transparent shadow lg:shadow-none scale-[0.8] sm:scale-100">
                                        <div className="relative">
                                            <span className="block absolute right-[0px] top-[0px]">
                                                <svg className="ml-auto mb-[4px]" width="78" height="57" viewBox="0 0 78 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.08">
                                                        <path d="M5.50357 56.0343H22.0143L33.0214 34.02V0.998535H0V34.02H16.5107L5.50357 56.0343ZM49.5321 56.0343H66.0429L77.05 34.02V0.998535H44.0286V34.02H60.5393L49.5321 56.0343Z" fill="#01614E" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="sp-brand text-tiny capitalize inline-block mb-[5px] xl:mb-[10px]">Hassle Free</span>
                                            <h2 className="font-lora text-primary text-[24px] xl:text-xl capitalize mb-[10px] xl:mb-[20px] leading-[1.2] font-medium">
                                                Maximize Your <br className="hidden xl:block" /> Property Value<span className="sp-brand">.</span>
                                            </h2>
                                            <p className="max-w-[480px] pb-3">Struggling to find a buyer who meets your price? Unsure of your property's true market value? Let Oren Access handle it for you. Our expert team conducts comprehensive market analyses to ensure you get the best deal.</p>
                                            <button type="" className="inline-block z-[1] before:rounded-md before:block before:absolute before:left-auto before:right-0 before:inset-y-0 before:z-[-1] before:bg-secondary before:w-0 hover:before:w-full hover:before:left-0 hover:before:right-auto before:transition-all leading-none px-[30px] py-[12px] capitalize font-medium text-white text-[14px] xl:text-[16px] relative after:block after:absolute after:inset-0 after:z-[-2] after:bg-primary after:rounded-md after:transition-all">
                                                Create a Listing
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide">
                                <div className="pl-[50px] xl:pl-[150px]">
                                    <div className="inline-block relative bg-primary text-primary rounded-[30px] z-10">
                                        <img src={Person2} className="w-auto h-auto block mx-auto relative z-[2] thumb" loading="lazy" width="402" height="505" alt="Oliver Stephen" />
                                        <img src={PersonPattern} className="absolute left-[0px] top-0 z-[1]" width="400" height="500" loading="lazy" alt="shape" />
                                    </div>
                                </div>
                                <div className="before:absolute before:top-1/2 before:-translate-y-1/2 before:left-0 before:w-full before:h-[395px] before:content-[''] before:bg-[#F5F9F8] before:rounded-[30px]">
                                    <div className="text-left rounded-[14px] max-w-[100%] sm:max-w-[90%] md:max-w-[560px] mx-auto md:ml-auto absolute top-[65%] sm:top-1/2 left-0 md:left-auto right-0 md:right-[50px] xl:right-0 -translate-y-1/2 px-[20px] md:px-[30px] xl:pl-[0px] xl:pr-[60px] py-[20px] md:py-[30px] z-20 bg-white xl:bg-transparent shadow lg:shadow-none scale-[0.8] sm:scale-100">
                                        <div className="relative">
                                            <span className="block absolute right-[0px] top-[0px]">
                                                <svg className="ml-auto mb-[4px]" width="78" height="57" viewBox="0 0 78 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.08">
                                                        <path d="M5.50357 56.0343H22.0143L33.0214 34.02V0.998535H0V34.02H16.5107L5.50357 56.0343ZM49.5321 56.0343H66.0429L77.05 34.02V0.998535H44.0286V34.02H60.5393L49.5321 56.0343Z" fill="#01614E" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <span className="sp-brand text-tiny capitalize inline-block mb-[5px] xl:mb-[10px]">Trouble Free</span>
                                            <h2 className="font-lora text-primary text-[24px] xl:text-xl capitalize mb-[10px] xl:mb-[20px] leading-[1.2] font-medium">
                                                Stress-Free <br className="hidden xl:block" /> Home Selling<span className="sp-brand">.</span>
                                            </h2>
                                            <p className="max-w-[480px] pb-3">Tired of the hassle of selling your home? Trust Oren Access to make the process seamless and affordable. Our professional realtors are dedicated to meeting your needs and exceeding your expectations.</p>
                                            <button type="" className="inline-block z-[1] before:rounded-md before:block before:absolute before:left-auto before:right-0 before:inset-y-0 before:z-[-1] before:bg-secondary before:w-0 hover:before:w-full hover:before:left-0 hover:before:right-auto before:transition-all leading-none px-[30px] py-[12px] capitalize font-medium text-white text-[14px] xl:text-[16px] relative after:block after:absolute after:inset-0 after:z-[-2] after:bg-primary after:rounded-md after:transition-all">
                                                Create a Listing
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        <div className="testimonial-pagination hidden sm:block">
                            <div className="swiper-button-prev w-[36px] h-[36px] rounded-full bg-secondary xl:bg-primary text-white hover:bg-secondary top-auto bottom-[85px] left-[30px]"></div>
                            <div className="swiper-button-next w-[36px] h-[36px] rounded-full bg-secondary xl:bg-primary text-white hover:bg-secondary top-auto bottom-[85px] left-[85px]"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;
