import Heart from '../../../images/icon/heart.svg'
function MemberCard({data}){
	return(
		<div className="overflow-hidden rounded-md text-center transition-all duration-300 drop-shadow-[0px_2px_5px_rgba(0,0,0,0.1)] bg-[#FFFDFC] hover:-translate-y-[10px] flex flex-wrap flex-col md:flex-row items-end">
			<div className="relative mb-[15px] lg:mb-[0px] block w-full lg:w-[300px]">
				<a href="" className="block h-[250px]">
					<img src={`/images/agents/${data.image}`} className="w-full h-full rounded-tl-[6px] lg:rounded-bl-[6px] object-cover" loading="lazy" width="300" height="250" alt={data.name} />
				</a>

			</div>

			<div className="flex flex-col relative w-full lg:w-[calc(100%-300px)]">
				<div className="text-left px-4 lg:px-0 w-full md:w-auto md:flex-1 lg:mr-7 xl:mr-[55px] bg-[#FFFDFC] lg:ml-[30px]">
					<h3><a href="" className="font-lora leading-tight text-[22px] xl:text-[26px] text-primary font-medium">{data.name}</a></h3>
					<h4><a href="" className="font-light text-tiny underline">{data.sales}</a></h4>
					<span className="font-light text-sm block">{data.priceRange}</span>
					<ul>
						<li className="flex flex-wrap items-center justify-between mt-[15px] mb-[10px] pt-[10px] border-t border-[#E0E0E0]">
							<span className="font-lora text-base text-primary leading-none font-medium">{data.phoneNumber}</span>
							<span className="flex flex-wrap items-center">

								<button className="text-[#B1AEAE] hover:text-secondary">
									<img src={Heart} />
								</button>
							</span>
						</li>
					</ul>
				</div>

				<ul className="flex flex-nowrap items-center bg-[#F4F4F4] w-full mt-5">
					<p className="text-center mx-auto truncate w-[90%]">{data.report}</p>
				</ul>
			</div>
		</div>

	)
}

export default MemberCard